import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import theme from "../../../../lib/theme";

interface SettingsListItemTemplateSpec extends Lightning.Component.TemplateSpec {
    label: string;
    isSelected: boolean;
    Icon: object;
    Label: object;
}

export class SettingsListItem
    extends Lightning.Component<SettingsListItemTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsListItemTemplateSpec>
{
    _label!: string;
    _value!: string;

    readonly Icon = this.getByRef("Icon")!;
    readonly Label = this.getByRef("Label")!;

    static override _template(): Lightning.Component.Template<SettingsListItemTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            Icon: {
                w: 24,
                h: 24,
                x: 0,
                y: (h) => h / 2 - 4,
                mountY: 0.5,
                src: undefined
            },
            Label: {
                x: 42,
                y: (h) => h / 2,
                mountY: 0.5,
                text: {
                    fontSize: 28,
                    textColor: Colors(theme.color.text).get(),
                    fontFace: "SemiBold"
                }
            }
        };
    }

    static get width() {
        return 1000;
    }

    static get height() {
        return theme.settings.itemH;
    }

    set isSelected(value: boolean) {
        this.Icon.patch({
            src: value ? Utils.asset(`icons/check.png`) : undefined
        });
    }

    set label(value: string) {
        this._label = value;
        this.Label.patch({
            text: {
                text: value
            }
        });
    }

    get label() {
        return this._label;
    }

    set value(value: string) {
        this._value = value;
    }

    get value() {
        return this._value;
    }

    override _focus() {
        this.Label.patch({
            text: {
                textColor: Colors(theme.color.textHighlight).get()
            }
        });
    }

    override _unfocus() {
        this.Label.patch({
            text: {
                textColor: Colors(theme.color.text).get()
            }
        });
    }

    override _handleEnter() {
        this.fireAncestors("$optionSelected", this._value);
    }

    _handleClick() {
        this._handleEnter();

        this.focusIn();
    }

    focusIn() {
        const list = this.parent?.parent?.parent;

        // @ts-expect-error no types available yet,
        list.setIndex(this.parent.componentIndex);
    }
}
