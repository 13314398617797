import { List } from "@lightningjs/ui";
import { Colors, Img, Lightning, Router, Utils } from "@lightningjs/sdk";
import { PAGE, PAGES } from "../../lib/utils";
import { MenuItem } from "./components/MenuItem";
import theme from "../../lib/theme";
import configs from "../../lib/configs";
import posthog from "posthog-js";

interface MenuTemplateSpec extends Lightning.Component.TemplateSpec {
    Logo: object;
    List: typeof List;
    BottomList: typeof List;
}

export class Menu
    extends Lightning.Component<MenuTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MenuTemplateSpec>
{
    _activePage: undefined | string = configs.defaultPage;
    _items: any[] = [];

    _boundEventHandler?: any;

    _hideMenuHandler: () => void = () => {};
    _showMenuHandler: () => void = () => {};

    static override _template(): Lightning.Component.Template<MenuTemplateSpec> {
        return {
            w: theme.menu.w,
            h: theme.layout.screenH,
            rect: true,
            collision: true,
            color: Colors(theme.color.primary).get(),
            Logo: {
                mountX: 0.5,
                x: theme.menu.w / 2,
                y: theme.logo.y,
                // pivotX: -0.35,
                pivotY: 0.25,
                scale: 0.5,
                texture: Img(Utils.asset("images/logo.png")).contain(theme.logo.w, theme.logo.h)
            },
            List: {
                y: 180,
                x: theme.menu.itemOffset,
                collision: true,
                type: List,
                direction: "column",
                spacing: 12
            },
            BottomList: {
                y: 800,
                x: theme.menu.itemOffset,
                collision: true,
                type: List,
                direction: "column",
                spacing: 12,
                items: [
                    {
                        type: MenuItem,
                        page: PAGES.info,
                        icon: "info",
                        label: "nav.info"
                        // activeId: this._activePage
                    },
                    {
                        type: MenuItem,
                        page: PAGES.settings,
                        icon: "settings",
                        label: "nav.settings"
                        // activeId: this._activePage
                    },
                    {
                        type: MenuItem,
                        page: PAGES.exit,
                        icon: "exit",
                        label: "nav.exit"
                        // activeId: this._activePage
                    }
                ]
            }
        };
    }

    readonly List = this.getByRef("List")!;
    readonly BottomList = this.getByRef("BottomList")!;

    override _setup() {
        this._boundEventHandler = (args: any) => {
            this._setActivePage(this, args);
        };

        this._hideMenuHandler = () => this.hideMenu();
        this._showMenuHandler = () => this.showMenu();

        this._items = [];
        if (configs.modules.search) {
            this._items.push({
                type: MenuItem,
                page: PAGES.search,
                icon: "search",
                label: "nav.search",
                activeId: this._activePage
            });
        }

        if (configs.modules.hoteltvWelcome) {
            this._items.push({
                type: MenuItem,
                page: PAGES.hoteltvWelcome,
                icon: "home",
                label: "nav.home",
                activeId: this._activePage
            });
        }

        if (configs.modules.live) {
            this._items.push({
                type: MenuItem,
                page: PAGES.live,
                icon: "live",
                label: "nav.live",
                activeId: this._activePage
            });
        }

        if (configs.modules.vod) {
            this._items.push({
                type: MenuItem,
                page: PAGES.vod,
                icon: configs.defaultPage === "vod" ? "home" : "vod",
                label: configs.defaultPage === "vod" ? "nav.home" : "nav.browse",
                activeId: this._activePage
            });
        }

        if (configs.modules.watchlist) {
            this._items.push({
                type: MenuItem,
                page: PAGES.favorite,
                icon: "bookmarks",
                label: "nav.watchlist",
                activeId: this._activePage
            });
        }

        this.List.patch({
            items: this._items
        });

        for (const item of this.BottomList.items) {
            item.activeId = this._activePage;
        }

        for (const wrapper of [...this.List.children, ...this.BottomList.children]) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    override _getFocused() {
        return this.List;
    }

    override _handleUp() {
        // needed to prevent focus on page
    }

    override _handleLeft() {
        // needed to prevent focus on page
    }

    override _handleRight() {
        Router.focusPage();
    }

    override _handleDown() {
        this._setState("SettingsState");
    }

    override _attach() {
        this.application.on("setActivePage", this._boundEventHandler);
        this.application.on("hideMenu", this._hideMenuHandler);
        this.application.on("showMenu", this._showMenuHandler);
    }

    override _detach() {
        this.application.off("setActivePage", this._boundEventHandler);
        this.application.off("hideMenu", this._hideMenuHandler);
        this.application.off("showMenu", this._showMenuHandler);
    }

    override _focus() {
        this.patch({
            smooth: {
                w: theme.menu.activeW
            },
            Logo: {
                smooth: {
                    scale: 1,
                    x: theme.menu.activeW / 2
                }
            }
        });

        this._updateList();

        try {
            const index = this.List.items.findIndex((i: any) => i.isActive && i.isActive());

            if (index === -1) {
                this._setState("SettingsState");
                this.List.setIndex(this._items.length - 1);
            } else {
                this._setState("");
                this.List.setIndex(index);
            }
        } catch (e) {
            console.log(e);
        }
    }

    override _unfocus() {
        this.patch({
            smooth: {
                w: theme.menu.w
            },
            Logo: {
                smooth: {
                    x: theme.menu.w / 2,
                    scale: 0.5
                }
            }
        });

        this._updateList();
    }

    private _setActivePage(self: any = this, id: string) {
        const menuPage = Object.values(PAGES).find((p) => p.name === id);
        if (menuPage) {
            posthog.capture("MenuItemOpened", {
                menu: menuPage.name,
                timestamp: new Date().toISOString()
            });
        }

        this._updateList(id);
    }

    _updateList(activePage?: string) {
        for (const item of [...this.List.items, ...this.BottomList.items]) {
            item.setFocus && item.setFocus(this.hasFocus());
            if (typeof item.setActiveId !== "undefined") {
                item.setActiveId(activePage);
            }
        }
    }

    static override _states() {
        return [
            class MenuState extends this {},
            class SettingsState extends this {
                override _getFocused() {
                    return this.BottomList;
                }

                override _handleUp() {
                    this._setState("MenuState");
                }

                override $exit() {
                    this.List.setIndex(this._items.length - 1);
                }
            }
        ];
    }

    private hideMenu() {
        this.patch({ smooth: { alpha: 0 } });
    }

    private showMenu() {
        this.patch({ smooth: { alpha: 1 } });
    }
}
