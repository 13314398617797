import { Colors, Lightning } from "@lightningjs/sdk";
import { Button } from "./Button";
import theme from "../../../lib/theme";

export class LargeButton extends Button {
    override _getBtnColor() {
        return this._disabled
            ? Colors(theme.button.disabledColor).get()
            : this.hasFocus() || this._isActive
              ? Colors(theme.livetvButton.bgFocus).get()
              : Colors(theme.livetvButton.bg).get();
    }

    override _createBackground() {
        this.patch({
            RoundedRectangle: {
                texture: Lightning.Tools.getRoundRect(
                    this.w,
                    this.h,
                    theme.livetvButton.radius,
                    0,
                    undefined,
                    true,
                    this._getBtnColor()
                )
            }
        });

        this.Label.patch({
            text: {
                fontSize: theme.livetvButton.fontSize,
                fontFamily: theme.livetvButton.fontFamily,
                lineHeight: theme.livetvButton.lineHeight,
                textColor: Colors(theme.livetvButton.textColor).get()
            }
        });
    }
}
