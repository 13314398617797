import { Lightning } from "@lightningjs/sdk";
import { List } from "@lightningjs/ui";
import theme from "../../../lib/theme";
import { ChannelModel, ProgramModel } from "../../../lib/models";
import { ChannelItem } from "../ChannelItem/ChannelItem";
import { getLiveTvChannels } from "../../../api/queries";

interface ChannelListSpec extends Lightning.Component.TemplateSpec {
    Container: {
        List: typeof List;
    };
}

export class ChannelList
    extends Lightning.Component<ChannelListSpec>
    implements Lightning.Component.ImplementTemplateSpec<ChannelListSpec>
{
    private _channels: ChannelModel[] = [];

    static override _template(): Lightning.Component.Template<ChannelListSpec> {
        return {
            w: theme.layout.screenW - theme.menu.w,
            h: theme.layout.screenH,
            flex: { direction: "column" },
            collision: true,
            zIndex: 10,
            Container: {
                h: theme.layout.screenH,
                w: 617,
                x: 0,
                y: 0,
                clipping: true,
                collision: true,
                List: {
                    type: List,
                    collision: true,
                    direction: "column",
                    spacing: 24,
                    h: theme.layout.screenH,
                    w: 607,
                    y: 0,
                    x: 0,
                    scroll: { after: 2 },
                    gcThreshold: 24,
                    requestThreshold: 20,
                    enableRequests: true,
                    signals: { onRequestItems: true },
                    scrollTransition: {
                        duration: 0.3,
                        timingFunction: "cubic-bezier(0.4, 0, 0.2, 1)"
                    }
                }
            }
        };
    }

    readonly Container = this.getByRef("Container")!;
    readonly List = this.Container.getByRef("List")! as List;

    override _getFocused(): Lightning.Component | null | undefined {
        return this.List;
    }

    _calculateProgress(program: ProgramModel | undefined, now: Date): number {
        if (!program) return 0;

        const start = new Date(program.start);
        const stop = new Date(program.stop);
        const totalDuration = stop.getTime() - start.getTime();
        const elapsed = now.getTime() - start.getTime();
        return Math.round((elapsed / totalDuration) * 100);
    }

    _findNextProgram(programs: ProgramModel[], now: Date): ProgramModel | undefined {
        return programs.find((program) => {
            const start = new Date(program.start);
            return now < start;
        });
    }

    _findCurrentProgram(programs: ProgramModel[], now: Date): ProgramModel | undefined {
        return programs.find((program) => {
            const start = new Date(program.start);
            const stop = new Date(program.stop);
            return now >= start && now < stop;
        });
    }

    _createChannelItem = (channel: ChannelModel, index: number) => {
        const now = new Date();
        const currentProgram = channel.programs ? this._findCurrentProgram(channel.programs, now) : undefined;
        const nextProgram = channel.programs ? this._findNextProgram(channel.programs, now) : undefined;
        const progress = this._calculateProgress(currentProgram, now);

        return {
            type: ChannelItem,
            item: {
                ...channel,
                channelNumber: (index + 1).toString().padStart(3, "0"),
                currentProgram,
                nextProgram,
                progress
            }
        };
    };

    reload(channels: ChannelModel[]): void {
        this._channels = channels;
        const items = channels.map(this._createChannelItem);
        this.List.reload(items);
    }

    addItems(channels: ChannelModel[]): void {
        const startIndex = this._channels.length;
        this._channels = [...this._channels, ...channels];

        if (this.List.items?.length) {
            const newItems = channels.map((channel, index) => this._createChannelItem(channel, startIndex + index));
            this.List.add(newItems);
        } else {
            this.reload(channels);
        }
    }

    async onRequestItems() {
        return (await getLiveTvChannels(this.List.items.length)).map((channel: ChannelModel, index: number) =>
            this._createChannelItem(channel, this.List.items.length + index)
        );
    }

    get items() {
        return this.List.items;
    }

    get currentItem() {
        return this.List.currentItem;
    }

    get index() {
        return this.List.index;
    }

    set index(value: number) {
        this.List.setIndex(value);
    }
}
