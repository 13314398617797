import { Lightning, Launch, PlatformSettings, AppData } from "@lightningjs/sdk";
import { App } from "./App.js";

import "./lib/polyfills";
import "./lib/iap";
import theme from "./lib/theme";

export default function (
    appSettings: Lightning.Application.Options,
    platformSettings: PlatformSettings,
    appData: AppData
) {
    if (!platformSettings.inspector) {
        document.body.style.background = theme.color.container;

        const styleTag = document.createElement("style");
        styleTag.innerHTML = `
          canvas, video {
            max-width: 120vw;
            max-height: 100vh;
            min-height: 100vh;
            object-position: top;
          }
          canvas {
            object-fit: contain;
          }
          video {
            object-fit: fill;
            width: auto !important
          }
        `;
        document.head.appendChild(styleTag);
    }

    return Launch(App, appSettings, platformSettings, appData);
}
