import { Colors, Lightning, Router } from "@lightningjs/sdk";
import { CategoryList } from "../components/data-display/CategoryList/CategoryList";
import { PAGES } from "../lib/utils";
import { CategoryModel } from "../lib/models";
import { TranslatableText } from "../components/data-display/TranslatableText/TranslatableText";
import theme from "../lib/theme";

interface FavoriteTemplateSpec extends Lightning.Component.TemplateSpec {
    Empty: {
        Text: typeof TranslatableText;
    };
    List: typeof CategoryList;
}

interface FavoriteTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Favorite
    extends Lightning.Component<FavoriteTemplateSpec, FavoriteTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<FavoriteTemplateSpec>
{
    readonly List = this.getByRef("List")!;
    readonly Empty = this.getByRef("Empty")!;

    static override _template(): Lightning.Component.Template<FavoriteTemplateSpec> {
        return {
            Empty: {
                alpha: 0,
                x: theme.menu.w,
                w: theme.layout.screenW - theme.menu.w,
                h: theme.layout.screenH,
                Text: {
                    w: 360,
                    mount: 0.5,
                    x: (w) => w / 2,
                    y: (h) => h / 2,
                    type: TranslatableText,
                    key: "bookmarks.empty",
                    text: {
                        textAlign: "center",
                        fontSize: 32,
                        lineHeight: 42,
                        textColor: Colors(theme.color.textDark).get()
                    }
                }
            },
            List: {
                type: CategoryList,
                x: theme.menu.w
            }
        };
    }

    set data(value: CategoryModel[]) {
        const filtered = value.filter((item) => item.items && item.items.length && item.items[0]?.id);

        if (filtered.length === 0) {
            this.List.patch({
                alpha: 0
            });
            this.Empty.patch({
                alpha: 1
            });
        } else {
            this.Empty.patch({
                alpha: 0
            });
            this.List.patch({
                alpha: 1
            });
            if (this.List.items.length !== filtered.length) {
                this.List.reload(filtered);
            } else {
                this.List.reloadMovies(filtered);
            }
        }
    }

    override _getFocused() {
        return this.List;
    }

    override _firstActive() {
        this.application.emit("clearBackground");
        this.application.emit("setActivePage", PAGES.favorite.name);
    }

    override _handleBack() {
        if (!Router.getHistory().length) return true;
        Router.go(Router.getHistory().length * -1);
    }

    override _inactive() {
        this.stage.gc();
    }
}
