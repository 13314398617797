import { Colors, Lightning, Router } from "@lightningjs/sdk";

import { TranslatableText } from "../components/data-display/TranslatableText/TranslatableText";
import { getHomePage, PAGES } from "../lib/utils";
import { SettingsMainList } from "../components/data-display/Settings/SettingsMainList";
import theme from "../lib/theme";
import { Account, Device, Localization, Profile } from "@firebolt-js/sdk";

interface SettingsTemplateSpec extends Lightning.Component.TemplateSpec {
    Page: {
        Content: {
            Title: typeof TranslatableText;
            List: typeof SettingsMainList;
        };
    };
}

interface SettingsTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Settings
    extends Lightning.Component<SettingsTemplateSpec, SettingsTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<SettingsTemplateSpec>
{
    static override _template(): Lightning.Component.Template<SettingsTemplateSpec> {
        return {
            Page: {
                collision: true,
                w: theme.layout.screenW,
                h: theme.layout.screenH,
                y: 52,
                x: theme.menu.w + theme.layout.contentX,
                flex: { direction: "column", justifyContent: "flex-start", alignItems: "flex-start" },
                Content: {
                    collision: true,
                    w: 1000,
                    flex: { direction: "column" },
                    List: {
                        color: Colors(theme.settings.bg || theme.color.primary).get(),
                        rect: true,
                        collision: true,
                        // h: 200,
                        w: theme.settings.w,
                        type: SettingsMainList,
                        signals: {
                            handleHover: "_setMainState"
                        }
                    }
                }
            }
        };
    }

    readonly Page = this.getByRef("Page")!;
    readonly Content = this.Page.getByRef("Content")!;
    readonly List = this.Content.getByRef("List")!;

    override _getFocused() {
        return this.List;
    }

    override _firstActive() {
        this.application.emit("clearBackground");
        this.application.emit("setActivePage", PAGES.settings.name);
    }

    override async _active() {
        console.log({ make: await Device.make() });
        console.log({ distributor: await Device.distributor() });
        console.log({ model: await Device.model() });
        console.log({ id: await Account.id() });
        console.log({ uid: await Account.uid() });
        console.log({ locale: await Localization.locale() });
        console.log({ countryCode: await Localization.countryCode() });
        console.log({ approveContentRating: await Profile.approveContentRating() });
    }

    override _inactive() {
        this.stage.gc();
    }
}

interface SettingsItemContainerTemplateSpec extends Lightning.Component.TemplateSpec {
    content: any;
    Content: any;
}

export class SettingsItemContainer
    extends Lightning.Component<SettingsItemContainerTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsItemContainerTemplateSpec>
{
    _content: any;

    static override _template(): Lightning.Component.Template<SettingsItemContainerTemplateSpec> {
        return { collision: true };
    }

    // @ts-ignore
    override _getFocused() {
        return this.children[0];
    }

    set content(value: any) {
        this.children = [
            {
                type: value
            }
        ];
        this._content = value;
    }

    get content() {
        return this._content;
    }

    static get width() {
        return theme.settings.w;
    }

    static get height() {
        return theme.settings.itemH;
    }

    override _handleBack() {
        if (!Router.getHistory().length) Router.navigate(getHomePage());
        Router.go(Router.getHistory().length * -1);
    }
}
