import { Colors, Lightning, Router, Utils } from "@lightningjs/sdk";
import { PAGE } from "../../../lib/utils";
import theme from "../../../lib/theme";
import { TranslatableText } from "../../../components/data-display/TranslatableText/TranslatableText";

interface MenuItemTemplateSpec extends Lightning.Component.TemplateSpec {
    activeId: undefined | string;
    page: PAGE;
    icon: string;
    label: string;
    Icon: {
        Background: object;
        Active: object;
        Inactive: object;
        Label: typeof TranslatableText;
    };
}

export class MenuItem
    extends Lightning.Component<MenuItemTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MenuItemTemplateSpec>
{
    _id = "";
    _link = "";
    _activeItem: undefined | string;

    _inFocus = false;

    private static _iconSize = 36;
    private static _bgSizeW = 95;
    private static _bgSizeH = 75;

    static override _template(): Lightning.Component.Template<MenuItemTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            w: this._bgSizeW,
            h: this._bgSizeH,
            Icon: {
                Background: {
                    alpha: 0,
                    texture: Lightning.Tools.getRoundRect(
                        this._bgSizeW,
                        this._bgSizeH,
                        6,
                        0,
                        Colors(theme.color.focus).alpha(1).get(),
                        true,
                        Colors(theme.color.focus).alpha(1).get()
                    )
                },
                Inactive: {
                    x: this._bgSizeW / 2,
                    y: this._bgSizeH / 2,
                    mount: 0.5,
                    h: this._iconSize,
                    w: this._iconSize
                },
                Label: {
                    type: TranslatableText,
                    key: "",
                    x: this._bgSizeW - 8,
                    y: this._bgSizeH / 2 + 3,
                    mountY: 0.5,
                    pivotX: 0,
                    scaleX: 0,
                    alpha: 0,
                    text: {
                        fontSize: 18
                    }
                }
                // Active: { x: 6, y: 6, alpha: 0.01 }
            }
        };
    }

    readonly Icon = this.getByRef("Icon")!;
    readonly Background = this.Icon.getByRef("Background")!;
    // readonly Active = this.Icon.getByRef("Active")!;
    readonly Inactive = this.Icon.getByRef("Inactive")!;
    readonly Label = this.Icon.getByRef("Label")!;

    static get width() {
        return this._bgSizeW;
    }

    static get height() {
        return this._bgSizeH;
    }

    get pageId() {
        return this._id;
    }

    set page(value: PAGE) {
        this._id = value.name;
        this._link = value.link;
    }
    set icon(value: string) {
        this.Inactive.patch({
            src: Utils.asset(`icons/${value}.png`)
        });

        // this.Active.patch({
        //     texture: Lightning.Tools.getSvgTexture(
        //         Utils.asset(`icons/${value}-active.svg`),
        //         this._iconSize,
        //         this._iconSize
        //     )
        // });
    }

    set label(value: string) {
        this.Label.patch({
            key: value
        });
    }

    set activeId(id: string | undefined) {
        this._activeItem = id;
        if (this._activeItem === this._id) {
            this._onFocus();
        } else {
            if (!this.hasFocus()) {
                this._onUnfocus();
            }
        }
    }

    get activeId() {
        return this._activeItem;
    }

    override _focus() {
        this._onFocus();
    }

    override _unfocus() {
        if (this._inFocus || this._activeItem !== this._id) {
            this._onUnfocus();
        }
    }

    override _handleEnter() {
        if (this._id === "exit") {
            this.application.emit("handleAppExit");
        } else {
            this.application.emit("setActivePage", this._id);

            Router.navigate(this._link);
            Router.focusPage();
        }
    }

    _handleClick() {
        this._handleEnter();
    }

    _onFocus() {
        this.Background.patch({
            texture: Lightning.Tools.getRoundRect(
                this._inFocus ? theme.menu.activeW - theme.menu.itemOffset * 2 : 95,
                75,
                2,
                0,
                Colors(theme.color.focus).alpha(1).get(),
                true,
                Colors(theme.color.focus).alpha(1).get()
            ),
            smooth: { alpha: 1, w: this._inFocus ? theme.menu.activeW - theme.menu.itemOffset * 2 : 95 }
        });
        this.Label.patch({
            smooth: { scaleX: this._inFocus ? 1 : 0, alpha: this._inFocus ? 1 : 0 }
        });
        // this.Active.patch({ smooth: { alpha: 1 } });
        // this.Inactive.patch({ smooth: { alpha: 0.01 } });
    }

    _onUnfocus() {
        this.Background.patch({
            texture: Lightning.Tools.getRoundRect(
                95,
                75,
                6,
                0,
                Colors(theme.color.focus).alpha(1).get(),
                true,
                Colors(theme.color.focus).alpha(1).get()
            ),
            smooth: {
                alpha: 0,
                w: this._inFocus ? theme.menu.activeW - theme.menu.itemOffset * 2 : 95
            }
        });
        this.Label.patch({
            smooth: { scaleX: this._inFocus ? 1 : 0, alpha: this._inFocus ? 1 : 0 }
        });
        // this.Active.patch({ smooth: { alpha: 0.01 } });
        // this.Inactive.patch({ smooth: { alpha: 1 } });
    }

    isActive() {
        return this._activeItem === this._id;
    }

    setFocus(value: boolean) {
        this._inFocus = value;
        this.setActiveId();
    }

    setActiveId(id?: string | undefined) {
        if (id) {
            this._activeItem = id;
        }
        if (!this._inFocus && this._activeItem === this._id) {
            this._onFocus();
        } else {
            if (!this.hasFocus()) {
                this._onUnfocus();
            }
        }
    }
}
