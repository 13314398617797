import { Colors, Lightning, Registry, Router, Storage, Utils } from "@lightningjs/sdk";
import { TranslatableText } from "../../components/TranslatableText/TranslatableText";
import { LoadingCircle } from "../../components/LoadingCircle/LoadingCircle";
import theme from "../../lib/theme";
import { STORAGE_KEYS, storageSaveUser } from "../../lib/utils";
import { getPayAlteoxSubscriptionLink, login, signup } from "../../api/mutations";
import { getUserStatus } from "../../api/queries";
import { IAPQr } from "./components/IAPQr";
import { IAPEmail } from "./components/IAPEmail/IAPEmail";
import { IAPEmailKeyboard } from "./components/IAPEmail/components/IAPEmailKeyboard/IAPEmailKeyboard";
import { IAPSubscriptionOptions } from "./components/IAPSubscriptionOptions";
import { Error } from "../../components/Error/Error";

interface IAPSubscriptionTemplateSpec extends Lightning.Component.TemplateSpec {
    Loading: typeof LoadingCircle;
    Error: typeof Error;
    Keyboard: typeof IAPEmailKeyboard;
    SubscriptionOptions: typeof IAPSubscriptionOptions;
    Login: {
        LogoContainer: {
            Logo: object;
        };
        Separator: any;
        QR: typeof IAPQr;
        Email: typeof IAPEmail;
    };
}

export class IAPSubscription
    extends Lightning.Component<IAPSubscriptionTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<IAPSubscriptionTemplateSpec>
{
    _selectedField = "";
    _loginData = {
        name: "",
        email: "",
        password: "",
        confirmPassword: ""
    };

    _loginLink = "";
    _signUpLink = "";

    _action: "login" | "signup" = "login";

    _activePage: undefined | string = "home";
    _items: any[] = [];

    _loginTimeout: undefined | number;

    _boundEventHandlers: any = {};

    _loading = false;
    _error = false;

    readonly Loading = this.getByRef("Loading")!;
    readonly Error = this.getByRef("Error")!;
    readonly Login = this.getByRef("Login")!;
    // readonly Content = this.Box.getByRef("Content")!;
    readonly QR = this.Login.getByRef("QR")!;
    readonly Email = this.Login.getByRef("Email")!;
    readonly Keyboard = this.getByRef("Keyboard")!;
    readonly SubscriptionOptions = this.getByRef("SubscriptionOptions")!;

    static override _template(): Lightning.Component.Template<IAPSubscriptionTemplateSpec> {
        return {
            w: theme.layout.screenW,
            h: theme.layout.screenH,
            x: 0,
            y: 0,
            rect: true,
            color: Colors(theme.color.overlay).alpha(0.8).get(),
            alpha: 0,
            zIndex: 40,
            collision: true,
            Loading: {
                w: theme.layout.screenW,
                h: theme.layout.screenH,
                type: LoadingCircle,
                alpha: 1,
                zIndex: 30
            },
            Error: {
                type: Error,
                label: "error.externalSubscription",
                zIndex: 30,
                alpha: 0
            },
            Login: {
                w: theme.layout.screenW,
                h: theme.layout.screenH,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                alpha: 0,
                rect: true,
                color: Colors(theme.color.overlay).alpha(0.9).get(),
                LogoContainer: {
                    h: 200,
                    w: 300,
                    mount: 0.5,
                    x: (w) => w / 2,
                    y: 200,
                    Logo: {
                        scale: 1.2,
                        texture: Lightning.Tools.getSvgTexture(
                            Utils.asset("images/logo.png"),
                            theme.logo.w,
                            theme.logo.h
                        )
                    }
                },
                Separator: {
                    mount: 0.5,
                    x: (w) => w / 2,
                    y: (y) => y / 2 + 75,
                    flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                    LineTop: {
                        rect: true,
                        color: Colors(theme.color.text).alpha(0.6).get(),
                        w: 2,
                        h: 200
                    },
                    Text: {
                        flexItem: {
                            marginTop: 10,
                            marginBottom: 10
                        },
                        type: TranslatableText,
                        key: "general.or",
                        text: {
                            textAlign: "center",
                            fontFace: "SemiBold",
                            fontSize: 26,
                            textColor: Colors(theme.color.text).alpha(0.6).get()
                        }
                    },
                    LineBottom: {
                        rect: true,
                        color: Colors(theme.color.text).alpha(0.6).get(),
                        w: 2,
                        h: 200
                    }
                },
                QR: {
                    mount: 0.5,
                    x: (w) => (w - w / 2) / 2,
                    y: (y) => y / 2 + 75,
                    w: 650,
                    type: IAPQr
                },
                Email: {
                    mount: 0.5,
                    x: (w) => (w + w / 2) / 2,
                    y: (y) => y / 2 + 75,
                    w: 650,
                    type: IAPEmail,
                    signals: {
                        onShowKeyboard: "_onShowKeyboard",
                        onLogin: "_onLogin",
                        onChangeAction: "_onChangeAction"
                    }
                }
            },
            SubscriptionOptions: {
                type: IAPSubscriptionOptions,
                zIndex: 20,
                alpha: 0,
                signals: {
                    onConfirmSubscription: "_onConfirmSubscription"
                }
            },
            Keyboard: {
                type: IAPEmailKeyboard,
                signals: {
                    onConfirmInput: "_onConfirmInput"
                },
                zIndex: 20,
                alpha: 0
            }
        };
    }

    override _getFocused() {
        return this.Email;
    }

    override _setup() {
        this._boundEventHandlers = {
            _toggleLoader: this._toggleLoader.bind(this)
        };
    }

    override _attach() {
        Registry.addEventListener(window, "externalSubscriptionSync", this._boundEventHandlers._toggleLoader);
    }

    override _detach() {
        Registry.addEventListener(window, "externalSubscriptionSync", this._boundEventHandlers._toggleLoader);
    }

    override _focus() {
        this.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    override _unfocus() {
        this.patch({
            smooth: {
                alpha: 0
            }
        });

        this._loginLink = "";
        this._signUpLink = "";
        this._action = "login";
        this._loginData = {
            name: "",
            email: "",
            password: "",
            confirmPassword: ""
        };

        this.Email.patch({
            loginData: this._loginData
        });
    }

    override _handleUp() {
        // needed to prevent focus on page
    }

    override _handleLeft() {
        // needed to prevent focus on page
    }

    override _handleRight() {
        // needed to prevent focus on page
    }

    override _handleDown() {
        //
    }

    _onChangeAction() {
        this._action = this._action === "login" ? "signup" : "login";

        this._initView();
    }

    async _onLogin() {
        console.log("ON LOGIN");
        this.Loading.patch({
            alpha: 1
        });

        try {
            let result;

            const data = {
                transactionId:
                    window.Android?.getSubscriptionData()?.receiptId ||
                    window.Android?.getSubscriptionData()?.purchaseToken ||
                    window.Android?.getPurchaseToken(),
                amazonUserId: window.Android?.getUserId(),
                subscriptionId: window.Android?.getSubscriptionData()?.subscriptionId
            };
            if (this._action === "signup") {
                result = await signup({
                    ...this._loginData,
                    ...data
                });
            } else {
                result = await login({
                    email: this._loginData.email,
                    password: this._loginData.password,
                    ...data
                });
            }

            console.log("result", result);

            Storage.set(STORAGE_KEYS.user, result.data);

            if (result.error || !result.data || !result.data?.isLoggedIn) {
                // this.Error.label = `error.auth.${result.error?.extensions?.code || `500`}`;
                this.Error.label = result.error?.message || `error.auth.${result.error?.extensions?.code || `500`}`;
                this._setState("ErrorState");
            } else if (result.data.subscribed) {
                Router.focusPage();
            } else {
                this._setState("SubscriptionOptionsState");
            }
        } catch (error) {
            console.log("iap err", error);
        }

        this.Loading.patch({
            alpha: 0
        });
    }

    _onConfirmInput({ input }: { input: string }) {
        // @ts-ignore
        this._loginData[this._selectedField] = input;

        this.Email.patch({
            loginData: this._loginData
        });
        this._setState("");
    }

    _onShowKeyboard({ field }: { field: "email" | "password" | "confirmPassword" }) {
        this._selectedField = field;

        this.Keyboard.patch({
            passwordMode: field === "password" || field === "confirmPassword",
            inputValue: this._loginData[field]
        });

        this._setState("KeyboardState");
    }

    _onConfirmSubscription({ price }: any) {
        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: {
                    name: "IAP: call createSubscriptionById",
                    data: {
                        id: price
                    }
                }
            })
        );

        window.Android!.createSubscriptionById(price);

        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: {
                    name: "IAP: called ",
                    data: {
                        id: price
                    }
                }
            })
        );
    }

    async _initView() {
        console.log("_initView");

        if (this._loading || this._error) {
            return;
        }

        let link = "";
        if (this._action === "login" && this._loginLink) {
            link = this._loginLink;
        } else if (this._signUpLink) {
            link = this._signUpLink;
        }

        if (!link) {
            this._setState("LoadingState");

            this.Login.patch({
                alpha: 0
            });

            const data = await getPayAlteoxSubscriptionLink({
                action: this._action,
                transactionId:
                    window.Android?.getSubscriptionData()?.receiptId ||
                    window.Android?.getSubscriptionData()?.purchaseToken ||
                    window.Android?.getPurchaseToken(),
                amazonUserId: window.Android?.getUserId(),
                subscriptionId: window.Android?.getSubscriptionData()?.subscriptionId
            });

            if (this._action === "login") {
                this._loginLink = data.url;
            } else {
                this._signUpLink = data.url;
            }

            link = data.url;
        }

        this.QR.patch({
            url: link
        });

        this.Email.patch({
            action: this._action
        });

        // this._setState("");

        // this.Login.patch({
        //     alpha: 1
        // });

        await this._checkLogin(true);
    }

    override _handleBack() {
        if (!this._loading) {
            Router.focusPage();
        }
    }

    override async _active() {
        this._action = "login";
        const user = Storage.get(STORAGE_KEYS.user);

        console.log("IAP _active", !user?.isLoggedIn);

        // if (!user?.isLoggedIn) {
        this._initView();
        // } else {
        //     this._setState("SubscriptionOptionsState");
        // }
    }

    override async _inactive() {
        this._setState("");

        this.Error.patch({
            alpha: 0
        });

        this.QR.patch({
            url: ""
        });
    }

    _toggleLoader({ detail }: any) {
        console.log(`IAP _toggleLoader: ${detail}`);

        const { syncing, error } = detail;

        this._loading = syncing;
        this._error = error;

        this.Login.patch({
            alpha: 0
        });

        this.SubscriptionOptions.patch({
            alpha: 0
        });

        if (syncing) {
            this._setState("LoadingState");
        } else if (error) {
            this.Error.label = "error.externalSubscription";
            this._setState("ErrorState");
        }

        console.log(
            `RESULT ${JSON.stringify({
                syncing,
                error
            })}`
        );

        if (syncing || error) {
            Router.focusWidget("IAPSubscription");
        } else {
            Router.focusPage();
        }
    }

    async _checkLogin(changeState = false) {
        try {
            const res = await getUserStatus();
            // console.log(`check login status res: ${JSON.stringify(res)}`);

            // console.log("state", this._getState());

            storageSaveUser(res);

            if (!res.isLoggedIn) {
                if (changeState) {
                    this._setState("");

                    this.Login.patch({
                        alpha: 1
                    });
                }

                if (Router.getActiveWidget()?.ref === "IAPSubscription") {
                    this._setLoginTimeout();
                }
            } else {
                // posthog.capture("SuccessfulAuth", {
                //     token: await deviceId()
                // });

                console.log(`check login res: ${JSON.stringify(res)}`);

                if (res.subscribed) {
                    Router.focusPage();
                } else {
                    this._setState("SubscriptionOptionsState");
                }
            }
        } catch (e) {
            this._setLoginTimeout();
        }
    }

    _setLoginTimeout() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this;
        this._loginTimeout = Registry.setTimeout(async () => {
            await _this._checkLogin(false);
        }, 5000);
    }

    static override _states() {
        return [
            class ErrorState extends this {
                override _getFocused(): any {
                    return this.Error;
                }

                override _handleEnter() {
                    this._setState("");
                }

                override _handleBack() {
                    this._setState("");
                }

                override $enter() {
                    this.Error.patch({
                        smooth: {
                            alpha: 1
                        }
                    });
                }

                override $exit() {
                    this._error = false;
                    this.Error.patch({
                        smooth: {
                            alpha: 0
                        }
                    });
                }
            },
            class LoadingState extends this {
                override _getFocused(): any {
                    return this.Loading;
                }

                override $enter() {
                    this.Loading.patch({
                        smooth: {
                            alpha: 1
                        }
                    });
                }

                override $exit() {
                    this.Loading.patch({
                        smooth: {
                            alpha: 0
                        }
                    });
                }
            },
            class SubscriptionOptionsState extends this {
                override _getFocused(): any {
                    return this.SubscriptionOptions;
                }

                override $enter() {
                    this.Login.patch({
                        alpha: 0
                    });

                    this.SubscriptionOptions.patch({
                        smooth: {
                            alpha: 1
                        }
                    });
                }

                override $exit() {
                    this.SubscriptionOptions.patch({
                        smooth: {
                            alpha: 0
                        }
                    });
                }
            },
            class KeyboardState extends this {
                override _getFocused(): any {
                    return this.Keyboard;
                }

                override _handleBack() {
                    this._setState("");
                }

                override $enter() {
                    this.Keyboard.patch({
                        smooth: {
                            alpha: 1
                        }
                    });
                }

                override $exit() {
                    this.Keyboard.patch({
                        smooth: {
                            alpha: 0
                        }
                    });
                }
            }
        ];
    }
}
