import { Colors, Img, Lightning, Utils } from "@lightningjs/sdk";
import { MediaType, MediaModel, ChannelItemModel } from "../../../lib/models";
import theme from "../../../lib/theme";
import { getMediaPosterProps } from "../../../lib/utils";

interface MediaPosterTemplateSpec extends Lightning.Component.TemplateSpec {
    data: MediaModel | ChannelItemModel;
    useBorder: boolean;
    useBackground: boolean;
    Background: {
        Poster: object;
        Placeholder: object;
        Radius: object;
        Border: object;
    };
}

export class MediaPoster
    extends Lightning.Component<MediaPosterTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MediaPosterTemplateSpec>
{
    _src: string | undefined;
    _type!: MediaType;
    _isActive = false;

    _useBackground = false;
    _useBorder = true;

    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<MediaPosterTemplateSpec> {
        return {
            Background: {
                w: (w) => w,
                h: (h) => h,
                // rect: true,
                // color: Colors(theme.color.backgroundNormal).get(),
                // shader: { type: Lightning.shaders.RoundedRectangle, radius: theme.mediaItem.radius },
                Placeholder: {
                    mount: 0.5,
                    w: theme.mediaPlaceholder.w,
                    h: theme.mediaPlaceholder.h,
                    x: (w) => w / 2,
                    y: (y) => y / 2,
                    texture: Img(Utils.asset("images/placeholder.png")).contain(
                        theme.mediaPlaceholder.w,
                        theme.mediaPlaceholder.h
                    )
                },
                Poster: {
                    w: (w) => w,
                    h: (h) => h,
                    mount: 0.5,
                    x: (w) => w / 2,
                    y: (y) => y / 2,
                    alpha: 0.01
                },
                Radius: {
                    x: -8,
                    y: -8,
                    alpha: 0
                },
                Border: {
                    mount: 0.5,
                    x: (w) => w / 2 + theme.mediaItem.highlightW / 2 - theme.mediaItem.highlightW / 2,
                    y: (y) => y / 2 + theme.mediaItem.highlightW / 2 - theme.mediaItem.highlightW / 2,
                    alpha: 0
                }
            }
        };
    }

    readonly Background = this.getByRef("Background")!;
    readonly Radius = this.Background.getByRef("Radius")!;
    readonly Border = this.Background.getByRef("Border")!;
    readonly Poster = this.Background.getByRef("Poster")!;
    readonly Placeholder = this.Background.getByRef("Placeholder")!;

    set data(value: MediaModel | ChannelItemModel) {
        if ("coverUrl" in value) {
            this._src = value?.coverUrl;
        } else {
            this._src = value?.logo;
        }
        this._type = value?.__typename;

        this._update();
    }

    set useBorder(value: boolean) {
        this._useBorder = value;
    }

    set useBackground(value: boolean) {
        this._useBackground = value;

        if (this._useBackground) {
            const props = getMediaPosterProps(this._type);

            const color = Colors(theme.color.backgroundLight).get();
            this.Background.patch({
                w: props.w,
                h: props.h,
                texture: Lightning.Tools.getRoundRect(props.w, props.h, props.radius, 0, undefined, true, color)
            });
        }
    }

    override _setup() {
        this._boundEventHandler = this._showPoster.bind(this);
    }

    override _init() {
        // @ts-expect-error: types are missing
        if (theme.mediaColors && theme.mediaColors[this.parent?.parent?.componentIndex % 4]) {
            // @ts-expect-error: types are missing
            const color = Colors(theme.mediaColors[this.parent!.parent!.componentIndex % 4]).get();
            this.Background.patch({
                texture: Lightning.Tools.getRoundRect(
                    theme.mediaItem.w,
                    theme.mediaItem.h,
                    theme.mediaItem.radius,
                    0,
                    undefined,
                    true,
                    color
                )
            });
        }

        this.Background.patch({
            shader: { type: Lightning.shaders.RoundedRectangle, radius: 4 }
        });
    }

    override _active() {
        this.Poster.on("txLoaded", this._boundEventHandler);

        this._isActive = true;

        this._update();
    }

    override _inactive() {
        this.Poster.off("txLoaded", this._boundEventHandler);

        this._isActive = false;

        this._update();

        // this.stage.gc();
    }

    override _focus() {
        this._createBackground();
    }

    override _unfocus() {
        this._createBackground();
    }

    _createBackground() {
        if (this._useBorder) {
            if (!this.Border.texture) {
                const props = getMediaPosterProps(this._type);

                this.Border.patch({
                    texture: Lightning.Tools.getRoundRect(
                        props.w,
                        props.h,
                        props.radius,
                        theme.mediaItem.highlightW,
                        Colors(theme.color.highlight).get(),
                        false
                    )
                });
            }

            this.Border.setSmooth("alpha", this.hasFocus() ? 1 : 0);
            this.Radius.patch({
                alpha: 1
            });
        }
    }

    _showPoster() {
        this.Placeholder.setSmooth("alpha", 0);
        this.Poster.setSmooth("alpha", 1);
    }

    _update() {
        if (this._isActive) {
            const { isChannel, ...props } = getMediaPosterProps(this._type);

            this._createBackground();

            if (this._src) {
                if (isChannel) {
                    this.Poster.patch({
                        w: undefined,
                        h: undefined,
                        texture: Img(`${this._src}?width=${props.posterWidth}&quality=80`).contain(
                            props.posterWidth,
                            props.posterHeight
                        )
                    });
                } else {
                    this.Poster.patch({
                        w: props.w,
                        h: props.posterHeight,
                        texture: Img(`${this._src}?width=${props.w}&quality=80`).cover(props.w, props.posterHeight)
                    });
                }
            }
        }
    }
}
