import { Colors, Lightning } from "@lightningjs/sdk";
import { TranslatableText } from "../../../../../components/data-display/TranslatableText/TranslatableText";
import theme from "../../../../../lib/theme";

interface IAPSubscriptionOptionSingleTemplateSpec extends Lightning.Component.TemplateSpec {
    data: string;
    Trial: typeof TranslatableText;
    Label: typeof TranslatableText;
}
export class IAPSubscriptionOptionSingle
    extends Lightning.Component<IAPSubscriptionOptionSingleTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<IAPSubscriptionOptionSingleTemplateSpec>
{
    Label = this.getByRef("Label")!;

    static override _template(): Lightning.Component.Template<IAPSubscriptionOptionSingleTemplateSpec> {
        return {
            h: 122,
            w: 770,
            Trial: {
                mountY: 0.5,
                x: 24,
                y: (y) => y / 2 - 14,
                type: TranslatableText,
                key: "subscriptions.freeTrial",
                text: {
                    textAlign: "center",
                    fontSize: 26,
                    textColor: Colors(theme.button.textColor).get()
                }
            },
            Label: {
                mountY: 0.5,
                x: 24,
                y: (y) => y / 2 + 16,
                type: TranslatableText,
                text: {
                    textAlign: "center",
                    fontSize: 22,
                    textColor: Colors(theme.button.textColor).get()
                }
            }
        };
    }

    set data(data: any) {
        this.Label.patch({
            translate: {
                key: data.key,
                value: data.value
            }
        });
    }

    override _active() {
        this._update();
    }

    override _focus() {
        this._update();
    }

    override _unfocus() {
        this._update();
    }

    _update() {
        this.patch({
            texture: Lightning.Tools.getRoundRect(
                770,
                122,
                5,
                0,
                undefined,
                true,
                Colors(this.hasFocus() ? theme.color.focus : theme.button.bg).get()
            )
        });
    }
}
