import { Colors, Lightning } from "@lightningjs/sdk";
import theme from "../../../../lib/theme";
import QRCode from "qrcode";
import { SettingsItemText } from "../layout/SettingsItemText";

interface SettingsAuthTemplateSpec extends Lightning.Component.TemplateSpec {
    Layout: typeof SettingsItemText;
}

class SettingsSubscriptionContent
    extends Lightning.Component<any>
    implements Lightning.Component.ImplementTemplateSpec<any>
{
    readonly QR = this.getByRef("QR")!;

    static override _template(): Lightning.Component.Template<any> {
        return {
            flex: { direction: "column", alignItems: "center", justifyContent: "center" },
            w: 540,
            QR: {
                w: 540,
                h: 540
            },
            Text: {
                y: 24,
                width: 340,
                text: {
                    text: "pay.alteox.com",
                    textAlign: "center",
                    textWidth: 340,
                    textColor: Colors(theme.color.text).get(),
                    fontSize: 28,
                    wrap: true,
                    wrapWidth: 800
                }
            }
        };
    }

    override async _init() {
        this.QR.patch({
            src: await QRCode.toDataURL("pay.alteox.com", {
                errorCorrectionLevel: "H",
                type: "image/jpeg"
            })
        });
    }
}

export class SettingsSubscription
    extends Lightning.Component<SettingsAuthTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsAuthTemplateSpec>
{
    readonly SettingsItem = this.getByRef("Layout")!;

    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<SettingsAuthTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            Layout: {
                type: SettingsItemText,
                title: "settings.manageSubscription",
                content: SettingsSubscriptionContent
            }
        };
    }

    override _getFocused() {
        return this.SettingsItem;
    }

    _handleHover() {
        this.fireAncestors("$listItemHover", this);
    }
}
