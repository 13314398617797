import { Colors, Lightning } from "@lightningjs/sdk";

import { PAGES } from "../lib/utils";
import { SettingsInfoList } from "../components/data-display/Settings/SettingsInfoList";
import theme from "../lib/theme";

interface InfoTemplateSpec extends Lightning.Component.TemplateSpec {
    Page: {
        Content: {
            List: typeof SettingsInfoList;
        };
    };
}

interface InfoTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class InfoPage
    extends Lightning.Component<InfoTemplateSpec, InfoTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<InfoTemplateSpec>
{
    static override _template(): Lightning.Component.Template<InfoTemplateSpec> {
        return {
            Page: {
                collision: true,
                w: theme.layout.screenW,
                h: theme.layout.screenH,
                y: 52,
                x: theme.menu.w + theme.layout.contentX,
                flex: { direction: "column", justifyContent: "flex-start", alignItems: "flex-start" },
                Content: {
                    collision: true,
                    w: 1000,
                    flex: { direction: "column" },
                    List: {
                        color: Colors(theme.settings.bg || theme.color.primary).get(),
                        rect: true,
                        collision: true,
                        type: SettingsInfoList,
                        w: theme.settings.w,
                        signals: {
                            handleHover: "_setInfoState"
                        }
                    }
                }
            }
        };
    }

    readonly Page = this.getByRef("Page")!;
    readonly Content = this.Page.getByRef("Content")!;
    readonly List = this.Content.getByRef("List")!;

    override _getFocused() {
        return this.List;
    }

    override _firstActive() {
        this.application.emit("clearBackground");
        this.application.emit("setActivePage", PAGES.info.name);
    }

    override _inactive() {
        this.stage.gc();
    }
}
