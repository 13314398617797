import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import { TranslatableObject, TranslatableText } from "../../TranslatableText/TranslatableText";
import { Carousel } from "@lightningjs/ui";
import { SettingsListItem } from "./SettingsListItem";
import theme from "../../../../lib/theme";

interface SettingsItemTemplateSpec extends Lightning.Component.TemplateSpec {
    title: string | TranslatableObject;
    selectedOption: string;
    selectedOptionKey: string;
    options: any[];
    valueKey: string;
    labelKey: string;

    info: string;

    Content: {
        // Label: typeof TranslatableText;
        Row: {
            Label: typeof TranslatableText;
            Icon: object;
        };
    };
    Border: object;

    ListContainer: {
        Border: object;
        List: typeof Carousel;
    };
}

export class SettingsCarouselItem
    extends Lightning.Component<SettingsItemTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsItemTemplateSpec>
{
    _labelKey = "label";
    _valueKey = "value";
    _options: any[] = [];
    _selectedOption: string | undefined;
    _focusList = false;

    readonly Content = this.getByRef("Content")!;
    // readonly Label = this.Content.getByRef("Label")!;
    readonly Row = this.Content.getByRef("Row")!;
    readonly Icon = this.Row.getByRef("Icon")!;

    readonly Label = this.Row.getByRef("Label")!;
    readonly ListContainer = this.getByRef("ListContainer")!;
    readonly List = this.ListContainer.getByRef("List")!;

    static override _template(): Lightning.Component.Template<SettingsItemTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            h: theme.settings.itemH,
            Content: {
                mountY: 0.5,
                y: (h) => h / 2,
                collision: true,
                zIndex: 10,
                flex: { direction: "column", alignItems: "flex-start", justifyContent: "flex-start" },
                Row: {
                    w: theme.settings.w - 46 * 2,
                    x: 46,
                    flex: { justifyContent: "space-between", alignItems: "center" },
                    Label: {
                        type: TranslatableText,
                        text: {
                            fontSize: 28,
                            textColor: Colors(theme.color.contrast).get(),
                            fontFace: "Secondary-Regular"
                        }
                    },
                    Icon: {
                        alpha: 0,
                        w: 15,
                        h: 27,
                        texture: Lightning.Tools.getSvgTexture(Utils.asset("icons/chevron-right.svg"), 15, 27)
                    }
                }
            },

            ListContainer: {
                collision: true,
                zIndex: 10,
                flexItem: { marginTop: 37 },
                h: theme.layout.screenH - 100,
                w: 552,
                clipping: true,
                y: 0,
                // y: -61,
                alpha: 0,
                x: theme.settings.w,
                Border: {
                    w: 2,
                    h: theme.layout.screenH - 100,
                    x: 52,
                    rect: true,
                    color: Colors(theme.color.textDark).alpha(0.5).get()
                },
                List: {
                    collision: true,
                    zIndex: 10,
                    y: 0,
                    x: 110,
                    w: 800,
                    clipping: true,
                    type: Carousel,
                    direction: "column",
                    spacing: 0,
                    scroll: {
                        after: 1 //start scrolling after 3 items
                    }
                }
            }
        };
    }

    set title(value: string | TranslatableObject) {
        if (typeof value === "string") {
            this.Label.patch({
                key: value
            });
        } else {
            this.Label.patch({
                translate: value
            });
        }
    }

    set selectedOption(value: string) {
        this._selectedOption = value;

        this._update();
    }

    set selectedOptionKey(value: string) {
        // this.Selected.patch({
        //     key: value
        // });
    }

    set options(value: any[]) {
        this._options = value;
        this._update();
    }

    set valueKey(value: string) {
        this._valueKey = value;
    }

    set labelKey(value: string) {
        this._labelKey = value;
        this._update();
    }

    set info(value: any) {
        this.Icon.patch({
            alpha: 1
        });
        this.List.patch({
            items: [
                {
                    type: TranslatableText,
                    key: value
                }
            ]
        });
        this._update();
    }

    override _init() {
        this.ListContainer.patch({
            // @ts-ignore
            y: -1 * this.parent?.parent?.parent?.assignedY || 0
        });
    }

    _update() {
        if (this._labelKey) {
            this.Icon.patch({
                alpha: 1
            });

            if (this._options.length) {
                const selectedIndex = this._options.findIndex((item) => item[this._valueKey] === this._selectedOption);

                this.List.patch({
                    h: this._options.length * theme.settings.itemH,
                    items: [
                        ...this._options.slice(selectedIndex, this._options.length),
                        ...this._options.slice(0, selectedIndex)
                    ].map((item) => ({
                        isSelected: this._selectedOption === item[this._valueKey],
                        type: SettingsListItem,
                        label: item[this._labelKey],
                        value: item[this._valueKey]
                    }))
                });

                for (const wrapper of this.List.children) {
                    wrapper?.patch({
                        collision: true
                    });
                }
            }
        }
    }

    get currentIndex() {
        return this.List.index;
    }

    override _focus() {
        // this.Selected.patch({
        //     text: {
        //         textColor: Colors("white").alpha(1).get()
        //     }
        // });

        this.Label.patch({
            text: {
                textColor: Colors(theme.color.textHighlight).get()
            }
        });

        if (this._options?.length) {
            this.ListContainer.patch({
                smooth: { alpha: 1 }
            });

            this.Icon.patch({
                alpha: 1
            });

            for (const wrapper of this.List.children) {
                for (const itemWrapper of wrapper.children) {
                    if (itemWrapper.children.length) {
                        if ((itemWrapper.children[0]! as SettingsListItem).label === this._selectedOption) {
                            (itemWrapper.children[0]! as SettingsListItem).focusIn();
                        }
                    }
                }
            }
        }
    }

    override _unfocus() {
        // this.Selected.patch({
        //     text: {
        //         textColor: Colors("white").alpha(0.4).get()
        //     }
        // });

        this.Label.patch({
            text: {
                textColor: Colors(theme.color.contrast).get()
            }
        });

        this.ListContainer.patch({
            smooth: {
                alpha: 0
            }
        });

        // this.Icon.patch({
        //     alpha: 0.01
        // });
    }

    override _handleLeft() {
        if (!this._focusList) return false;

        this._focusList = false;
    }

    override _handleRight() {
        if (!this._options?.length || this._options?.length === 1) return false;

        this._focusList = true;
    }

    focusList() {
        this._handleRight();
    }

    override _getFocused() {
        if (this._options?.length) {
            return this._focusList ? this.List : undefined;
        } else {
            return undefined;
        }
    }
}
