import { Lightning, Router } from "@lightningjs/sdk";
import { SecondaryButton } from "../../input/Button/SecondaryButton";

interface PlayerTopTemplateSpec extends Lightning.Component.TemplateSpec {
    Back: typeof SecondaryButton;
}

export class PlayerTop
    extends Lightning.Component<PlayerTopTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PlayerTopTemplateSpec>
{
    static override _template(): Lightning.Component.Template<PlayerTopTemplateSpec> {
        return {
            collision: true,
            Back: {
                w: 126,
                type: SecondaryButton,
                label: "general.back",
                icon: "back",
                signals: {
                    handleHover: "_handleHover",
                    handleClick: "_handleClick"
                }
            }
        };
    }

    readonly Back = this.getByRef("Back")!;

    override _getFocused() {
        return this.Back;
    }

    override _handleEnter() {
        this.fireAncestors("$exitVideo");
    }

    _handleClick() {
        this.fireAncestors("$exitVideo");
    }

    _handleHover() {
        this.signal("playerTopHovered");
    }
}
