import { Colors, Lightning } from "@lightningjs/sdk";
import { TranslatableText } from "../../data-display/TranslatableText/TranslatableText";
import theme from "../../../lib/theme";
import { Icon } from "../../data-display/Icon/Icon";

interface SecondaryButtonTemplateSpec extends Lightning.Component.TemplateSpec {
    label: string;
    icon: string;
    isEnlarged: boolean;
    RoundedRectangle: object;
    Content: {
        Icon: typeof Icon;
        Label: typeof TranslatableText;
    };
}

export class SecondaryButton
    extends Lightning.Component<SecondaryButtonTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SecondaryButtonTemplateSpec>
{
    readonly Content = this.getByRef("Content")!;
    readonly Label = this.Content.getByRef("Label")!;
    readonly Icon = this.Content.getByRef("Icon")!;

    static override _template(): Lightning.Component.Template<SecondaryButtonTemplateSpec> {
        return {
            h: 48,
            collision: true,
            zIndex: 20,
            Content: {
                h: (h) => h,
                flex: {
                    alignItems: "center",
                    justifyContent: "center"
                },
                Icon: {
                    w: 24,
                    h: 24,
                    type: Icon
                },
                Label: {
                    flexItem: { marginLeft: 8, marginRight: 8 },
                    y: 2,
                    type: TranslatableText,
                    text: {
                        textAlign: "center",
                        fontSize: 24,
                        textColor: Colors(theme.color.contrast).get()
                    },
                    alpha: 0
                }
            }
        };
    }

    override _getFocused(): Lightning.Component | null | undefined {
        return this.Icon;
    }

    set isEnlarged(value: boolean) {
        this.Icon.patch({
            w: 50,
            h: 50,
            iconWidth: 50
        });
        this.Label.patch({
            flexItem: { marginLeft: 30, marginRight: 30 },
            text: {
                fontSize: 48
            }
        });
    }

    set label(value: string) {
        this.Label.patch({
            key: value,
            alpha: 1
        });
    }

    set icon(value: string) {
        this.Icon.patch({
            icon: value,
            w: 24
        });
    }

    override _init() {
        this.Content.patch({ w: this.w, h: this.h });
    }

    override _focus() {
        this.Label.patch({
            text: {
                textColor: Colors(theme.color.textHighlight).get()
            }
        });
    }

    override _unfocus() {
        this.Label.patch({
            text: {
                textColor: Colors(theme.color.contrast).get()
            }
        });
    }

    _handleClick() {
        this.signal("handleClick");
    }

    _handleHover() {
        this.signal("handleHover");
    }
}
