import Hls from "hls.js";
import { Router } from "@lightningjs/sdk";
import { Metrics } from "@firebolt-js/sdk";
import { Player as BitmovinPlayer } from "bitmovin-player";
import configs from "./configs";
import theme from "./theme";

interface VideoConfig {
    startPosition?: number;
    autoplay?: boolean;
}

interface SourceConfig {
    hls?: string;
    dash?: string;
    progressive?: string;
}

let hls: Hls | null = null;
let bitmovinPlayer: InstanceType<typeof BitmovinPlayer> | null = null;

const defaults = {
    debug: false,
    autoplay: true,
    backBufferLength: 10,
    maxBufferLength: 30,
    maxBufferSize: 30
};

const handleUnrecoverableError = (player: Hls | InstanceType<typeof BitmovinPlayer>, errorEvent: string) => {
    // if (VideoPlayer._consumer) {
    //     VideoPlayer._consumer.fire("$videoPlayerError", errorEvent, VideoPlayer.currentTime);
    //     VideoPlayer._consumer.fire("$videoPlayerEvent", "Error", errorEvent, VideoPlayer.currentTime);
    // }
    if (player instanceof Hls) {
        player.destroy();
    } else if (player instanceof BitmovinPlayer) {
        console.log(errorEvent);
    }
};

const determineBitmovinSourceType = (url: string): SourceConfig => {
    if (url.includes(".m3u")) return { hls: url };
    if (url.includes(".mpd")) return { dash: url };

    return { progressive: url };
};

const initializeBitmovinPlayer = (videoEl: HTMLVideoElement, src: string): InstanceType<typeof BitmovinPlayer> => {
    let wrapper = document.getElementById("bitmovin-wrapper");

    if (wrapper) {
        if (bitmovinPlayer) {
            return bitmovinPlayer;
        }
    } else {
        wrapper = document.createElement("div");
        wrapper.id = "bitmovin-wrapper";

        const videoRect = videoEl.getBoundingClientRect();

        Object.assign(wrapper.style, {
            position: "absolute",
            top: "0",
            left: "0",
            width: `${videoRect.width}px`,
            height: `${videoRect.height}px`
            // zIndex: "1",
            // display: "none"
        });

        videoEl.parentElement?.insertBefore(wrapper, videoEl);
    }

    const match = src.match(/\/([^\/]+)\/index\.m3u8/);
    const title = match ? match[1] : undefined;

    const player = new BitmovinPlayer(wrapper, {
        ui: false,
        playback: {
            autoplay: true
        },
        key: configs.bitmovin.bitmovinPlayerKey,
        analytics: {
            key: configs.bitmovin.bitmovinAnalyticsKey,
            title: title,
            config: {
                origin: configs.appId
            }
        },
        style: {
            width: "100%",
            height: "100%"
        },
        logs: {
            // level: "debug"
        },
        tweaks: {
            file_protocol: true,
            app_id: configs.appId,
            minimal_backward_buffer_clearing_interval: 5
        }
    });

    player.setVideoElement(videoEl);

    return player;
};

export const destroyPlayer = () => {
    if (bitmovinPlayer) {
        bitmovinPlayer.destroy();
        bitmovinPlayer = null;
    }

    document.querySelector("#bitmovin-wrapper")?.remove();
};

const unload = (videoEl: HTMLVideoElement) => {
    console.log("hls", hls);
    if (hls?.destroy) {
        hls.destroy();
        hls = null;
    } else if (bitmovinPlayer) {
        // bitmovinPlayer.destroy();
        // bitmovinPlayer = null;
        bitmovinPlayer.unload();
    }

    console.log("videoEl", videoEl);
    if (videoEl) {
        videoEl.removeAttribute("src");
    }
};

const loader = (src: string, videoEl: HTMLVideoElement, config: VideoConfig = {}): Promise<void> => {
    return new Promise(async (resolve) => {
        unload(videoEl);

        videoEl.setAttribute("poster", "/static/images/poster.png");
        videoEl.style.setProperty("background", theme.color.container);

        if (configs.bitmovin.useBitmovin) {
            console.log("Loading bitmovin source:", src);
            bitmovinPlayer = window.bitmovinPlayer || initializeBitmovinPlayer(videoEl, src);

            window.bitmovinPlayer = bitmovinPlayer;

            const sourceConfig = determineBitmovinSourceType(src);
            console.log("Loading source:", sourceConfig);

            await bitmovinPlayer!.load(sourceConfig);

            console.log("Bitmovin player loaded successfully");

            if (config.startPosition) {
                bitmovinPlayer?.seek(config.startPosition);
            }

            resolve();
        } else {
            if (src.indexOf(".m3u") > -1 && Hls.isSupported()) {
                console.log("Loading hls source:", src);
                hls = new Hls({ ...defaults, ...config });

                hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                    console.log("video and hls.js are now bound together !");
                });
                hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                    console.log("manifest loaded, found " + data.levels.length + " quality level");
                    resolve();
                });

                hls.on(Hls.Events.ERROR, function (event, data) {
                    console.log("ERROR", data);
                    if (hls) {
                        if (data.fatal) {
                            switch (data.type) {
                                case Hls.ErrorTypes.NETWORK_ERROR:
                                    console.log("fatal network error encountered, try to recover");
                                    Metrics.error(
                                        Metrics.ErrorType.MEDIA,
                                        "NETWORK_ERROR",
                                        "playback network error",
                                        true
                                    );

                                    if (navigator.onLine) {
                                        hls.startLoad();
                                        switch (data.details) {
                                            // case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
                                            // videoEl.dispatchEvent(new Event("stalled"));
                                            // break;

                                            case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                                                if (data.frag) {
                                                    hls.currentLevel = data.frag.start + data.frag.duration + 0.1;
                                                } else {
                                                    hls.startLoad();
                                                }
                                                break;

                                            case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
                                                handleUnrecoverableError(hls, event);
                                                break;

                                            default:
                                                hls.startLoad();
                                                break;
                                        }
                                    } else {
                                        Router.navigate("!");
                                    }

                                    break;
                                case Hls.ErrorTypes.MEDIA_ERROR:
                                    console.log("fatal media error encountered, try to recover");
                                    Metrics.error(Metrics.ErrorType.MEDIA, "MEDIA_ERROR", "playback media error", true);

                                    switch (data.details) {
                                        case Hls.ErrorDetails.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
                                            handleUnrecoverableError(hls, event);
                                            break;
                                        default:
                                            hls.recoverMediaError();
                                            break;
                                    }

                                    break;
                                default:
                                    Metrics.error(
                                        Metrics.ErrorType.MEDIA,
                                        "MEDIA_FATAL_ERROR",
                                        "playback fatal error",
                                        true
                                    );

                                    // cannot recover
                                    handleUnrecoverableError(hls, event);
                                    break;
                            }
                        } else {
                            // switch (data.type) {
                            //     case Hls.ErrorTypes.MEDIA_ERROR:
                            //         switch (data.details) {
                            //             case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
                            //                 videoEl.dispatchEvent(new Event("stalled"));
                            //                 break;
                            //         }
                            //         break;
                            // }
                        }
                    }
                });

                hls.loadSource(src);
                hls.attachMedia(videoEl);
            } else {
                videoEl.setAttribute("src", src);
                videoEl.load();

                videoEl.currentTime = config.startPosition || 0;

                videoEl
                    .play()
                    .then((r) => console.log("play"))
                    .catch((e) => console.log(e));

                resolve();
            }
        }
    });
};

const unloader = (videoEl: HTMLVideoElement): Promise<void> => {
    return new Promise((resolve) => {
        unload(videoEl);
        resolve();
    });
};

export { VideoConfig, loader, unloader };
