import { Lightning, Storage } from "@lightningjs/sdk";
import { List } from "@lightningjs/ui";
import { SettingsLanguage } from "./SettingsMain/SettingsLanguage";
import { SettingsAuth } from "./SettingsMain/SettingsAuth";
import { SettingsItemContainer } from "../../../pages/Settings";
import { isExternalPlatform, isPostTvPlatform, STORAGE_KEYS } from "../../../lib/utils";
import theme from "../../../lib/theme";
import configs from "../../../lib/configs";
import { SettingsAudioLanguage } from "./SettingsMain/SettingsAudioLanguage";
import { SettingsSubLanguage } from "./SettingsMain/SettingsSubLanguage";
import { SettingsSubscription } from "./SettingsMain/SettingsSubscription";

interface SettingsMainListTemplateSpec extends Lightning.Component.TemplateSpec {
    List: typeof List;
}

interface SettingsMainListTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class SettingsMainList
    extends Lightning.Component<SettingsMainListTemplateSpec, SettingsMainListTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<SettingsMainListTemplateSpec>
{
    static override _template(): Lightning.Component.Template<SettingsMainListTemplateSpec> {
        return {
            collision: true,
            w: (w) => w,
            List: {
                collision: true,
                w: (w: number) => w / 2,
                flexItem: { marginTop: 37 },
                x: 0,
                type: List,
                direction: "column",
                signals: { onIndexChanged: true }
                // spacing: 64
            }
        };
    }

    readonly List = this.getByRef("List")!;

    override _getFocused() {
        return this.List;
    }

    override _setup() {
        for (const wrapper of this.List.children) {
            wrapper?.patch({
                collision: true
            });
        }

        const items: any[] = [{ type: SettingsItemContainer, content: SettingsLanguage }];

        if (configs.playback.audioLanguages) {
            items.push({ type: SettingsItemContainer, content: SettingsAudioLanguage });
        }

        if (configs.playback.subtitleLanguages) {
            items.push({ type: SettingsItemContainer, content: SettingsSubLanguage });
        }

        console.log(
            "!isPostTvPlatform() && Storage.get(STORAGE_KEYS.user)?.userManagement !== false",
            !isPostTvPlatform(),
            Storage.get(STORAGE_KEYS.user),
            Storage.get(STORAGE_KEYS.user)?.userManagement !== false
        );

        if (!isPostTvPlatform() && Storage.get(STORAGE_KEYS.user)?.userManagement !== false) {
            if (!isExternalPlatform()) {
                items.push({ type: SettingsItemContainer, content: SettingsSubscription });
            }

            items.push({ type: SettingsItemContainer, content: SettingsAuth });
        }

        const height = theme.settings.itemH * items.length;

        this.patch({
            h: height
        });

        this.List.patch({
            h: height,
            items
        });
    }

    onIndexChanged(indexData: any) {
        // @ts-ignore
        this.List.selected;
    }

    $listItemHover(e: any) {
        const index = this.List.items.findIndex((item: any) => item === e.parent);
        this.List.setIndex(index);

        // @ts-ignore
        this.signal("handleHover");

        return true;
    }
}
