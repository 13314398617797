import { Colors, Lightning, Registry, Router } from "@lightningjs/sdk";
import { PAGES } from "../lib/utils";
// @ts-expect-error no types available yet
import { Keyboard, InputField, List } from "@lightningjs/ui";
import { KeyboardKey } from "../components/KeyboardKey/KeyboardKey";
import { MovieList } from "../components/MovieList/MovieList";
import { SearchSelectedMovie } from "../components/SearchSelectedMovie/SearchSelectedMovie";
import { search } from "../api/queries";
import { MovieDetailsModel } from "../lib/models";
import { LoadingCircle } from "../components/LoadingCircle/LoadingCircle";
import { TranslatableText } from "../components/TranslatableText/TranslatableText";
import theme from "../lib/theme";

interface SearchTemplateSpec extends Lightning.Component.TemplateSpec {
    Page: {
        InputContainer: {
            Wrapper: {
                Input: typeof InputField;
            };
        };
        KeyboardContainer: {
            Keyboard: typeof Keyboard;
        };
        ListWrapper: {
            List: typeof List;
        };
        SelectedMovie: typeof SearchSelectedMovie;
        Loading: typeof LoadingCircle;
        NotFound: {
            Text: typeof TranslatableText;
        };
    };
}

interface SearchTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

const keyboardConfig = {
    layouts: {
        ABC: [
            ["A", "B", "C", "D", "E", "F"],
            ["G", "H", "I", "J", "K", "L"],
            ["M", "N", "O", "P", "Q", "R"],
            ["S", "T", "U", "V", "W", "X"],
            ["Y", "Z", "1", "2", "3", "4"],
            ["5", "6", "7", "8", "9", "0"],
            ["Clear", "Space", "Backspace"]
        ]
    },
    buttonTypes: {
        default: {
            type: KeyboardKey,
            label: {
                text: {
                    fontSize: 32
                }
            }
        },
        Clear: {
            type: KeyboardKey,
            w: 188,
            icon: "clear"
        },
        Space: {
            type: KeyboardKey,
            w: 188,
            icon: "space"
        },
        Backspace: {
            type: KeyboardKey,
            w: 188,
            icon: "backspace"
        }
    },
    styling: {
        align: "center", //aligns the rows when the width of the Keyboard component is bigger than 0 (zero).
        horizontalSpacing: 4, //spacing between the keys
        verticalSpacing: 4 //spacing between the rows
    }
};

export class Search
    extends Lightning.Component<SearchTemplateSpec, SearchTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<SearchTemplateSpec>
{
    _selectedMovie: undefined | MovieDetailsModel;
    _timeout: undefined | number;
    _abortController!: AbortController;
    _isSearchState = false;
    _isSearchLoading = false;

    _listIndex? = 0;

    static override _template(): Lightning.Component.Template<SearchTemplateSpec> {
        return {
            collision: true,
            Page: {
                collision: true,
                x: theme.menu.w,
                InputContainer: {
                    y: 70,
                    x: theme.layout.contentX,
                    w: theme.keyboard.inputW,
                    h: 66,
                    clipping: true,
                    texture: Lightning.Tools.getRoundRect(
                        theme.keyboard.inputW,
                        66,
                        6,
                        0,
                        undefined,
                        true,
                        Colors(theme.keyboard.inputBg).get()
                    ),
                    Wrapper: {
                        x: 37,
                        y: 6,
                        w: (w) => w,
                        h: (h) => h,
                        clipping: true,
                        Input: {
                            y: 4,
                            w: theme.keyboard.inputW - 37 * 2,
                            type: InputField,
                            inputText: {
                                fontSize: 32,
                                lineHeight: 36,
                                textColor: Colors(theme.color.text).get()
                            },
                            cursor: {
                                h: 48,
                                w: 2,
                                color: Colors(theme.color.text).get()
                            },
                            maxLabelWidth: 570 - 37 * 2,
                            labelPositionStatic: false
                        }
                    }
                },
                KeyboardContainer: {
                    collision: true,
                    y: 152,
                    x: theme.layout.contentX,
                    rect: true,
                    // color: Colors(theme.keyboard.color).get(),
                    w: 572,
                    h: 669,
                    texture: Lightning.Tools.getRoundRect(
                        theme.keyboard.inputW,
                        669,
                        6,
                        0,
                        undefined,
                        true,
                        Colors(theme.keyboard.color).get()
                    ),
                    // shader: { type: Lightning.shaders.RoundedRectangle, radius: 6 },
                    // rect: true,
                    // w: theme.keyboard.keyboardW + 1,
                    // h: 680,
                    Keyboard: {
                        collision: true,
                        type: Keyboard,
                        config: keyboardConfig,
                        currentLayout: "ABC",
                        maxCharacters: 35,
                        signals: { onInputChanged: true, onSpace: true }
                    }
                },
                ListWrapper: {
                    collision: true,
                    clipping: true,
                    y: 123,
                    x: theme.layout.contentX + theme.keyboard.keyboardW + 52,
                    w: theme.layout.screenW - (theme.layout.contentX + theme.keyboard.keyboardW + 62 + theme.menu.w),
                    h: 900,
                    List: {
                        type: List,
                        x: 22,
                        w: (w: number) => w,
                        h: (h: number) => h,
                        direction: "column"
                    }
                },
                Loading: {
                    type: LoadingCircle,
                    alpha: 0,
                    y: 0,
                    x: theme.layout.contentX + theme.keyboard.keyboardW + 62,
                    w: theme.layout.screenW - (theme.layout.contentX + theme.keyboard.keyboardW + 62 + theme.menu.w),
                    h: theme.layout.screenH - 123
                },
                NotFound: {
                    alpha: 0,
                    y: 0,
                    x: theme.layout.contentX + theme.keyboard.keyboardW + 62,
                    w: theme.layout.screenW - (theme.layout.contentX + theme.keyboard.keyboardW + 62 + theme.menu.w),
                    h: theme.layout.screenH - 123,
                    Text: {
                        mount: 0.5,
                        x: (w) => w / 2,
                        y: (h) => h / 2,
                        type: TranslatableText,
                        key: "search.empty",
                        text: {
                            textAlign: "center",
                            textColor: Colors(theme.color.text).get()
                        }
                    }
                }
            }
        };
    }

    readonly Page = this.getByRef("Page")!;
    readonly List = this.Page.getByRef("ListWrapper")!.getByRef("List")!;
    readonly InputContainer = this.Page.getByRef("InputContainer")!;
    readonly InputContainerWrapper = this.InputContainer.getByRef("Wrapper")!;
    readonly Input = this.InputContainerWrapper.getByRef("Input")!;

    readonly KeyboardContainer = this.Page.getByRef("KeyboardContainer")!;
    readonly Keyboard = this.KeyboardContainer.getByRef("Keyboard")!;

    readonly SelectedMovie = this.Page.getByRef("SelectedMovie")!;
    readonly Loading = this.Page.getByRef("Loading")!;
    readonly NotFound = this.Page.getByRef("NotFound")!;

    override _setup() {
        this._abortController = new AbortController();

        this.Keyboard.inputField(this.Input);

        this._setState("SearchState");
    }

    override historyState(params?: { index: number }) {
        if (params?.index) {
            this._listIndex = params.index;
        } else {
            return {
                index: this._listIndex
            };
        }
    }

    override _getFocused(): Lightning.Component | null | undefined {
        return this.Keyboard;
    }

    override _active() {
        this.application.emit("clearBackground");
        this.application.emit("setActivePage", PAGES.search.name);

        for (const item of this.Keyboard.children) {
            item.patch({
                collision: true
            });

            for (const subItem of item.children) {
                subItem.patch({
                    collision: true
                });
            }
        }
    }

    override _inactive() {
        this._cancelSearch();
        // this._finishSearch();

        // this.List.clear();

        this.stage.gc();
    }

    _cancelSearch() {
        if (this._isSearchLoading) {
            this._abortController.abort();

            this._abortController = new AbortController();
        }

        if (this._timeout) {
            Registry.clearTimeout(this._timeout);
            this._timeout = undefined;
        }
    }

    _finishSearch(data: undefined | MovieDetailsModel[] = undefined) {
        this._isSearchState = false;

        if (data) {
            this.List.reload(
                data.map((item) => ({
                    type: MovieList,
                    items: item.items,
                    x: 0,
                    navigateOnSelect: true
                }))
            );

            if (this._listIndex) {
                this.List.index = this._listIndex;
                this._listIndex = undefined;
            }
        }

        this._toggleLoader();
    }

    _toggleLoader() {
        if (this._isSearchState) {
            this._setState("SearchState");
        }

        this.Loading.patch({
            smooth: {
                alpha: this._isSearchState ? 1 : 0
            }
        });

        const listHasItems = this.List.items.length > 0;

        this.List.patch({
            smooth: {
                alpha: this._isSearchState || !listHasItems ? 0 : 1
            }
        });

        this.NotFound.patch({
            smooth: {
                alpha: this._isSearchState || listHasItems ? 0 : 1
            }
        });
    }

    onInputChanged({ input }: { input: string }) {
        this._cancelSearch();

        this._timeout = Registry.setTimeout(() => {
            this._timeout = undefined;
            this._isSearchState = true;
            this._toggleLoader();

            (async () => {
                this.List.clear();

                this._isSearchLoading = true;

                if (!input.length) {
                    this.NotFound.patch({
                        smooth: {
                            alpha: 0
                        }
                    });
                    this._finishSearch();
                } else {
                    try {
                        const data = await search(
                            {
                                query: input,
                                page: 1
                            },
                            this._abortController.signal
                        );
                        this._finishSearch(data);
                    } catch (e: any) {
                        if (!e.aborted && e.name !== "AbortError") {
                            this._finishSearch();
                        }
                    }
                }

                this._isSearchLoading = false;
            })();
        }, 660);
    }

    $categorySelected() {
        this._setState("ResultsState");
    }

    $focusKey(key: string) {
        this.Keyboard.focus(key);
        this._setState("SearchState");

        window.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 13, code: "13" }));
    }

    static override _states() {
        return [
            class SearchState extends this {
                override _handleRight() {
                    if (this.List.items.length > 0 && !this._isSearchLoading) {
                        this._setState("ResultsState");
                    }
                }
            },
            class ResultsState extends this {
                override _getFocused() {
                    return this.List;
                }

                override _handleLeft() {
                    this._setState("SearchState");
                }

                $movieSelected(data: MovieDetailsModel) {
                    this._listIndex = this.List.index;
                    this._selectedMovie = data;
                    // this._setState("ResultSingleState");
                }
            },
            class ResultSingleState extends this {
                override _getFocused() {
                    return this.SelectedMovie;
                }

                override $enter() {
                    // this.SelectedMovie.patch({ smooth: { alpha: 1 }, data: this._selectedMovie });
                    this.List.patch({ smooth: { alpha: 0 } });
                }

                override $exit() {
                    this.List.patch({ smooth: { alpha: 1 } });
                    // this.SelectedMovie.patch({ smooth: { alpha: 0 } });
                }

                override _handleLeft() {
                    this._setState("SearchState");
                }

                override _handleBack() {
                    if (!this.List.items.length) this.onInputChanged({ input: this.Input.input });

                    this._setState("ResultsState");
                    return true;
                }
            }
        ];
    }

    override _handleBack() {
        if (!Router.getHistory().length) return true;

        Router.go(Router.getHistory().length * -1);

        return true;
    }
}
