import { Lightning } from "@lightningjs/sdk";

interface MovieNameTemplateSpec extends Lightning.Component.TemplateSpec {
    maxW: number;
}

export class MovieName
    extends Lightning.Component<MovieNameTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MovieNameTemplateSpec>
{
    static override _template(): Lightning.Component.Template<MovieNameTemplateSpec> {
        return {
            text: {
                text: "",
                fontSize: 35,
                fontFace: "Heavy",
                wordWrap: true,
                lineHeight: 35,
                wordWrapWidth: 1400,
                maxLines: 3,
                textOverflow: "ellipsis"
            }
        };
    }

    set maxW(w: number) {
        this.patch({
            text: {
                wordWrapWidth: w
            }
        });
    }
}
