import { Lightning, Colors, Router } from "@lightningjs/sdk";
import { TranslatableText } from "../components/data-display/TranslatableText/TranslatableText";
import { LargeButton } from "../components/input/Button/LargeButton";
import theme from "../lib/theme";

interface HotelTVWelcomeTemplateSpec extends Lightning.Component.TemplateSpec {
    Background: object;
    Content: {
        Title: typeof TranslatableText;
        Subtitle: typeof TranslatableText;
        ButtonContainer: {
            HotelButton: typeof LargeButton;
            GuestHouseButton: typeof LargeButton;
        };
    };
}

export class HotelTVWelcome
    extends Lightning.Component<HotelTVWelcomeTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<HotelTVWelcomeTemplateSpec>
{
    static override _template(): Lightning.Component.Template<HotelTVWelcomeTemplateSpec> {
        return {
            Background: {
                w: theme.layout.screenW,
                h: theme.layout.screenH,
                rect: true,
                color: Colors(theme.liveHome.background.color).get()
            },
            Content: {
                x: theme.liveHome.content.x,
                y: theme.liveHome.content.y,
                mount: 0.5,
                flex: {
                    direction: "column",
                    alignItems: "center"
                },
                Title: {
                    type: TranslatableText,
                    key: "liveHome.title",
                    text: {
                        fontSize: theme.liveHome.content.title.fontSize,
                        fontFamily: theme.livetvButton.fontFamily,
                        textColor: Colors(theme.liveHome.content.title.textColor).get()
                    }
                },
                Subtitle: {
                    type: TranslatableText,
                    key: "liveHome.subtitle",
                    y: theme.liveHome.content.subtitle.y,
                    text: {
                        fontSize: theme.liveHome.content.subtitle.fontSize,
                        fontFamily: theme.livetvButton.fontFamily,
                        textColor: Colors(theme.liveHome.content.subtitle.textColor).get()
                    }
                },
                ButtonContainer: {
                    y: theme.liveHome.content.buttonContainer.y,
                    w: theme.livetvButton.width * 2 + theme.liveHome.content.buttonContainer.spacing,
                    flex: {
                        direction: "row",
                        justifyContent: "space-between"
                    },
                    HotelButton: {
                        type: LargeButton,
                        w: theme.livetvButton.width,
                        h: theme.livetvButton.height,
                        label: "liveHome.button.hotel",
                        actionId: "hotel",
                        useBorder: false,
                        isActive: false,
                        hasActive: false,
                        disabled: false
                    },
                    GuestHouseButton: {
                        type: LargeButton,
                        w: theme.livetvButton.width,
                        h: theme.livetvButton.height,
                        label: "liveHome.button.guesthouse",
                        actionId: "guesthouse",
                        useBorder: false,
                        isActive: false,
                        hasActive: false,
                        disabled: false
                    }
                }
            }
        };
    }

    private _index = 0;
    private _buttons = ["HotelButton", "GuestHouseButton"] as const;

    override _getFocused() {
        const buttonPath = this._buttons[this._index];
        return buttonPath ? this.tag(`Content.ButtonContainer.${buttonPath}`) : null;
    }

    override _handleLeft() {
        if (this._index === 0) {
            Router.focusWidget("Menu");
            return true;
        }
        this._index = Math.max(0, this._index - 1);
        return true;
    }

    override _handleRight() {
        this._index = Math.min(this._buttons.length - 1, this._index + 1);
        return true;
    }

    $handleBtnClick(actionId: string) {
        switch (actionId) {
            case "hotel":
                Router.navigate("hoteltv-login", { userType: "hotel" });
                break;
            case "guesthouse":
                Router.navigate("hoteltv-login", { userType: "guesthouse" });
                break;
        }
    }

    override _active() {
        this.application.emit("setActivePage", "hoteltv-welcome");
    }
}
