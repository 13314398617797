import { Lightning } from "@lightningjs/sdk";
import { Badge } from "../../data-display/Badge/Badge";
import { SeparatedText } from "../../data-display/SeparatedText/SeparatedText";
import { MovieName } from "../MovieName/MovieName";
import { BrandingModel, MovieDetailsModel, MediaModel } from "../../../lib/models";
import { MediaDynamicBanner } from "../MediaDynamicBanner/MediaDynamicBanner";
import theme from "../../../lib/theme";
import { MediaDurationWithProgress } from "../MediaDurationWithProgress/MediaDurationWithProgress";
import { Button } from "../../input/Button/Button";

interface MovieDetailsTemplateSpec extends Lightning.Component.TemplateSpec {
    maxW: number;
    data: MovieDetailsModel | BrandingModel;
    Background: typeof MediaDynamicBanner;
    Content: {
        ContentLeft: {
            SeriesName: object;
            Name: typeof MovieName;
            Labels: {
                Badge: typeof Badge;
                Year: typeof Object;
                Age: typeof SeparatedText;
                Time: typeof MediaDurationWithProgress;
                Separator: typeof SeparatedText;
            };
            Description: object;
            Link: typeof Button;
        };
        ContentRight: object;
    };
}

export class MovieDetails
    extends Lightning.Component<MovieDetailsTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MovieDetailsTemplateSpec>
{
    _boundEventHandler?: any;
    _isBranding = false;
    _data!: MovieDetailsModel | BrandingModel;

    static override _template(): Lightning.Component.Template<MovieDetailsTemplateSpec> {
        return {
            x: 0,
            y: 0,
            w: theme.banner.w - theme.menu.w,
            h: theme.banner.h,
            Background: {
                type: MediaDynamicBanner
            },
            Content: {
                w: (w) => w - theme.banner.padding,
                h: (h) => h - theme.banner.padding,
                x: theme.banner.padding,
                y: theme.banner.paddingY || theme.banner.padding,
                flex: { direction: "row" },
                ContentLeft: {
                    w: theme.banner.contentW,
                    flex: { direction: "column", justifyContent: "flex-start" },
                    SeriesName: {
                        text: {
                            text: "",
                            fontSize: 24,
                            fontFace: "Medium",
                            wordWrap: true,
                            lineHeight: 24,
                            wordWrapWidth: 1400,
                            maxLines: 3,
                            textOverflow: "ellipsis"
                        }
                    },
                    Name: {
                        maxW: theme.banner.contentW,
                        type: MovieName,
                        flexItem: { marginBottom: 18 }
                    },
                    Labels: {
                        alpha: 0,
                        flex: { direction: "row" },
                        flexItem: { marginBottom: 10 },
                        Year: {
                            text: {
                                text: "",
                                fontSize: 20,
                                fontFace: "Secondary-Regular"
                            }
                        },
                        Time: {
                            type: MediaDurationWithProgress,
                            fontSize: 20
                        }
                    },
                    Description: {
                        h: 150,
                        text: {
                            text: "",
                            fontSize: 26,
                            fontFace: "Secondary-Regular",
                            wordWrap: true,
                            lineHeight: 30,
                            wordWrapWidth: theme.banner.contentW,
                            maxLines: 4,
                            textOverflow: "ellipsis"
                        }
                    },
                    Link: {
                        type: Button,
                        label: "general.watch",
                        alpha: 0,
                        w: 156
                    }
                },
                ContentRight: {}
            }
        };
    }

    readonly Background = this.getByRef("Background")!;
    readonly Content = this.getByRef("Content")!;
    readonly ContentLeft = this.Content.getByRef("ContentLeft")!;
    readonly SeriesName = this.ContentLeft.getByRef("SeriesName")!;
    readonly Name = this.ContentLeft.getByRef("Name")!;
    readonly Labels = this.ContentLeft.getByRef("Labels")!;
    readonly Description = this.ContentLeft.getByRef("Description")!;
    readonly Time = this.Labels.getByRef("Time")!;
    readonly Year = this.Labels.getByRef("Year")!;
    readonly Link = this.ContentLeft.getByRef("Link")!;

    override _setup() {
        this._boundEventHandler = this._update.bind(this);
    }

    override _active() {
        this.application.on("updateSelectedMovie", this._boundEventHandler);
    }

    override _inactive() {
        this.application.off("updateSelectedMovie", this._boundEventHandler);
    }

    override _getFocused() {
        return this.Link;
    }

    _update(data: MediaModel | BrandingModel) {
        if (!this._isBranding || "releaseYear" in data) {
            const hasDuration = (data as MediaModel).duration;
            const year = (data as MediaModel).releaseYear?.toString() || "";

            this.Year.patch({
                text: {
                    text: year + (year && hasDuration ? "  •" : "")
                },
                flexItem: { marginRight: !!year ? 10 : 0 }
            });

            this.Time.patch({
                duration: (data as MediaModel).duration,
                progress: (data as MediaModel).progress?.current
            });

            this.SeriesName.patch({
                text: (data as MediaModel).season?.series?.title || (data as MediaModel)?.series?.title || ""
            });
        } else {
            this.Link.patch({
                alpha: !!(data as BrandingModel).movieId ? 1 : 0
            });
        }

        this.Description.patch({ text: data.description || "" });
        this.Name.patch({ text: data.title || "" });

        this.Labels.patch({
            alpha: 1
        });
    }

    set maxW(value: number) {
        this.Name.patch({
            maxW: value
        });
    }

    set data(value: MovieDetailsModel | BrandingModel) {
        this._data = value;

        this._update(value);
    }
}
