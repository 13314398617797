import { InputField } from "@lightningjs/ui";

export default class InputFieldExtended extends InputField {
    handleLeft(e: KeyboardEvent) {
        this._handleLeft!(e);
    }

    handleRight(e: KeyboardEvent) {
        this._handleRight!(e);
    }
}
