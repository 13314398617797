import { Language, Lightning, Storage } from "@lightningjs/sdk";
import { SettingsCarouselItem } from "../layout/SettingsCarouselItem";
import { STORAGE_KEYS } from "../../../../lib/utils";

interface SettingsLanguageTemplateSpec extends Lightning.Component.TemplateSpec {
    Layout: typeof SettingsCarouselItem;
}

export class SettingsSubLanguage
    extends Lightning.Component<SettingsLanguageTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsLanguageTemplateSpec>
{
    readonly SettingsItem = this.getByRef("Layout")!;

    static override _template(): Lightning.Component.Template<SettingsLanguageTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            Layout: {
                type: SettingsCarouselItem,
                title: "settings.defineSubtitlesLanguage"
            }
        };
    }

    override _getFocused() {
        return this.SettingsItem;
    }

    override _setup() {
        const options = Language.available();

        const selected = options.find((option: { code: string; name: string }) => option.code === Language.get());

        this.SettingsItem.patch({
            options: options,
            valueKey: "code",
            labelKey: "name",
            selectedOption: selected.name
        });
    }

    $optionSelected(code: string) {
        const selected = Language.available().find((lang: any) => lang.code === code);

        this.SettingsItem.patch({
            selectedOption: selected.name
        });

        code = selected?.code || "en";

        Storage.set(STORAGE_KEYS.subLang, code);
    }

    _handleHover() {
        this.fireAncestors("$listItemHover", this);
        this.SettingsItem.focusList();
    }
}
