import { Lightning, Router } from "@lightningjs/sdk";
import { ChannelList } from "../components/data-display/ChannelList/ChannelList";
import { PAGES } from "../lib/utils";
import theme from "../lib/theme";
import { ChannelItemModel, ChannelModel } from "../lib/models";
import { LiveVideoPlayer } from "../components/player/LiveVideoPlayer/LiveVideoPlayer";
import posthog from "posthog-js";
import { ChannelItem } from "../components/data-display/ChannelItem/ChannelItem";

interface LiveChannelsTemplateSpec extends Lightning.Component.TemplateSpec {
    List: typeof ChannelList;
    VideoPlayer: typeof LiveVideoPlayer;
    ChannelOverlay: typeof ChannelItem;
}

interface LiveChannelsTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class LiveChannels
    extends Lightning.Component<LiveChannelsTemplateSpec, LiveChannelsTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<LiveChannelsTemplateSpec>
{
    _showingList = true;
    _overlayTimeout: number | null = null;

    private _currentChannelStartTime: number | null = null;
    private _currentChannel: ChannelItemModel | null = null;

    readonly List = this.getByRef("List")!;
    readonly VideoPlayer = this.getByRef("VideoPlayer")!;
    readonly ChannelOverlay = this.getByRef("ChannelOverlay")!;

    static override _template(): Lightning.Component.Template<LiveChannelsTemplateSpec> {
        return {
            w: theme.layout.screenW,
            h: theme.layout.screenH,
            VideoPlayer: {
                type: LiveVideoPlayer
            },
            List: {
                type: ChannelList,
                x: theme.menu.w + 28,
                y: 40,
                w: theme.layout.screenW - theme.menu.w - 28,
                h: theme.layout.screenH - 40
            },
            ChannelOverlay: {
                type: ChannelItem,
                alpha: 0,
                x: theme.channelOverlay.contentX,
                y: theme.layout.screenH - theme.channelOverlay.bottomMargin - theme.channelOverlay.height
            }
        };
    }

    override _getFocused(): Lightning.Component | null | undefined {
        return this._showingList ? this.List : this.VideoPlayer;
    }

    override _firstActive() {
        this.application.emit("setActivePage", PAGES.live.name);
    }

    override _active() {
        if (window.selectedChannel) {
            const foundChannel = this.List.items.find(
                (item: { item: ChannelItemModel }) => item.item.name === window.selectedChannel?.name
            )?.item;

            if (foundChannel) {
                this._playChannel(foundChannel);
                this.List.index = this.List.items.findIndex(
                    (item: { item: ChannelItemModel }) => item.item.name === window.selectedChannel?.name
                );
            } else {
                this._playChannel(this.List.items[0].item);
                // todo: load channels
            }
        } else if (this.List.items.length > 0) {
            this._playChannel(this.List.items[0].item);
        }
    }

    override _inactive() {
        this._trackChannelWatchTime();
        this._currentChannel = null;
        this._currentChannelStartTime = null;
        this.stage.gc();
    }

    override _handleLeft() {
        return this._handleNavigation(-1);
    }

    override _handleRight() {
        return this._handleNavigation(1);
    }

    override _handleUp() {
        return this._toggleList();
    }

    override _handleDown() {
        return this._toggleList();
    }

    override _handleEnter() {
        if (this._currentChannel) {
            this._showOverlay(this._currentChannel);
        }
    }

    override _handleBack() {
        if (!this._showingList) {
            this._showList();
            return true;
        }

        return false;
        // if (!Router.getHistory().length) return false;
        // Router.go(Router.getHistory().length * -1);
    }

    $liveChannelSelected(channel: ChannelItemModel) {
        this.application.emit("hideMenu");
        this._hideList();
        this._playChannel(channel);
    }

    _showOverlay(channel: ChannelItemModel) {
        this.ChannelOverlay.item = channel;
        this.ChannelOverlay.patch({ smooth: { alpha: 1 } });

        if (this._overlayTimeout) {
            clearTimeout(this._overlayTimeout);
        }

        this._overlayTimeout = setTimeout(() => {
            this.ChannelOverlay.patch({ smooth: { alpha: 0 } });
        }, 5000) as unknown as number;
    }

    _toggleList(): boolean {
        if (!this._showingList) {
            this._showList();
        }
        return this._showingList;
    }

    _handleNavigation(direction: number): boolean {
        if (!this._showingList) {
            this.List.index += direction;
            const channel = this.List.items[this.List.index].item;
            this._playChannel(channel);
            this._showOverlay(channel);
            return true;
        }
        return false;
    }

    _trackChannelWatchTime() {
        if (this._currentChannel && this._currentChannelStartTime) {
            const watchTimeInSeconds = Math.floor((Date.now() - this._currentChannelStartTime) / 1000);

            posthog.capture("ChannelWatchTime", {
                channelId: this._currentChannel.id,
                channelName: this._currentChannel.name,
                watchTimeSeconds: watchTimeInSeconds
            });
        }
    }

    _playChannel(channel: ChannelItemModel) {
        if (!this.VideoPlayer.isChannelPlaying(channel)) {
            this._trackChannelWatchTime();
            this._currentChannel = channel;
            this._currentChannelStartTime = Date.now();
            this.VideoPlayer.channel = channel;
        }
    }

    _showList() {
        this.ChannelOverlay.patch({ smooth: { alpha: 0 } });

        this.application.emit("showMenu");
        this._showingList = true;
        this.List.patch({ smooth: { alpha: 1 } });
        this._refocus();
    }

    _hideList() {
        this._showingList = false;
        this.List.patch({ smooth: { alpha: 0 } });
    }

    set data(initialChannels: ChannelModel[]) {
        console.log("Initial channels received:", initialChannels.length);
        this.List.addItems(initialChannels);
    }
}
