import { Colors, Lightning, Registry, Router, VideoPlayer } from "@lightningjs/sdk";
import { loader, unloader } from "../lib/video-player";
import { PlayerTop } from "../components/player/Player/PlayerTop";
import { PlayerBottom } from "../components/player/Player/PlayerBottom";
import { Loading } from "../components/feedback/Loading/Loading";
import { PlaybackModel } from "../lib/models";
import theme from "../lib/theme";
import { updateProgress } from "../api/mutations";
import { Metrics } from "@firebolt-js/sdk";
import posthog from "posthog-js";
import { getHomePage } from "../lib/utils";

interface VideoPlayerEvent {
    event: Event;
    videoElement: HTMLVideoElement;
}

interface PlaybackTemplateSpec extends Lightning.Component.TemplateSpec {
    Overlay: {
        Info: {
            SeriesName: object;
            Name: object;
            Description: object;
        };
    };
    Loading: typeof Loading;
    TopContainer: {
        Top: typeof PlayerTop;
    };
    BottomContainer: {
        Controls: typeof PlayerBottom;
    };
}

interface PlaybackTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

const CONTROLS_HIDE_DELAY = 2000;
const LOADING_DELAY = 400;

const MIN_REWIND = 10;
const MAX_REWIND = 300; // 5 min max

export class Playback
    extends Lightning.Component<PlaybackTemplateSpec, PlaybackTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<PlaybackTemplateSpec>
{
    _duration = 0;
    _currentTime = 0;
    _playing = true;
    _controlsTimeout: undefined | number;
    _loadingTimeout: undefined | number;
    _loadingTimeoutDelay = LOADING_DELAY;

    _loading = true;
    _seeking = false;

    _playTimeout: undefined | number;
    _progressTimeout: undefined | number;

    _movieId: string | number | undefined;
    _shifting = false;
    _shiftTimeout: undefined | number;
    _controlsHideDelay = CONTROLS_HIDE_DELAY;
    _rewindSeconds = MIN_REWIND;

    _boundEventHandler: any;

    static _overlayPadding = 200;

    _startTime: number = 0;
    _movieTitle: string = "";

    static override _template(): Lightning.Component.Template<PlaybackTemplateSpec> {
        return {
            collision: true,
            Loading: { type: Loading, alpha: 1 },
            Overlay: {
                rect: true,
                w: theme.layout.screenW,
                h: theme.layout.screenH,
                color: Colors(theme.color.overlay).alpha(0.8).get(),
                alpha: 1,
                Info: {
                    x: 72,
                    w: 1000,
                    h: theme.layout.screenH - 200,
                    flex: {
                        direction: "column",
                        justifyContent: "flex-end"
                    },
                    SeriesName: {
                        zIndex: 2,
                        text: {
                            fontSize: 32,
                            lineHeight: 34
                        }
                    },
                    Name: {
                        zIndex: 2,
                        text: {
                            fontSize: 38,
                            lineHeight: 40
                        }
                    },
                    Description: {
                        zIndex: 2,
                        w: 1000,
                        text: {
                            fontSize: 28,
                            lineHeight: 30,
                            textOverflow: "ellipsis",
                            wordWrap: true,
                            maxLines: 20
                        }
                    }
                }
            },
            TopContainer: {
                collision: true,
                h: this._overlayPadding * 2,
                w: theme.layout.screenW,
                rect: true,
                colorTop: Colors(theme.color.overlay).alpha(0.6).get(),
                colorBottom: Colors(theme.color.overlay).alpha(0).get(),
                Top: {
                    type: PlayerTop,
                    x: 72,
                    y: 36,
                    signals: {
                        playerTopHovered: "_handlePlayerTopHovered"
                    }
                }
            },
            BottomContainer: {
                w: theme.layout.screenW,
                h: this._overlayPadding * 2,
                y: theme.layout.screenH - this._overlayPadding * 2,
                rect: true,
                colorTop: Colors(theme.color.overlay).alpha(0).get(),
                colorBottom: Colors(theme.color.overlay).alpha(0.6).get(),
                collision: true,
                Controls: {
                    y: this._overlayPadding * 2 - 82,
                    collision: true,
                    type: PlayerBottom
                    // x: PLAYER_OFFSETS.x,
                    // y: this._overlayPadding
                }
            }
        };
    }

    readonly Overlay = this.getByRef("Overlay")!;
    readonly Info = this.Overlay.getByRef("Info")!;
    readonly SeriesName = this.Info.getByRef("SeriesName")!;
    readonly Name = this.Info.getByRef("Name")!;
    readonly Description = this.Info.getByRef("Description")!;

    readonly TopContainer = this.getByRef("TopContainer")!;
    readonly Top = this.TopContainer.getByRef("Top")!;
    // readonly Controls = this.getByRef("Controls")!;
    readonly BottomContainer = this.getByRef("BottomContainer")!;
    readonly Controls = this.BottomContainer.getByRef("Controls")!;
    readonly Loading = this.getByRef("Loading")!;

    override _firstActive() {
        window.reloadContinueWatching = true;

        VideoPlayer.consumer(this);
        VideoPlayer.position(0, 0);
        // VideoPlayer.loader(loader);
        // VideoPlayer.unloader(unloader);
    }

    override _setup() {
        this._boundEventHandler = this._resumePlaying.bind(this);
    }

    override _active() {
        // this.application.emit("hideBackground");

        this._loadingTimeoutDelay = 0;

        this._setLoadingState();

        Registry.addEventListener(window, "online", this._boundEventHandler);
    }

    override _inactive() {
        // save in the background

        this.application.emit("showBackground");

        this._destroyPlayer();

        Registry.removeEventListener(window, "online", this._boundEventHandler);

        Metrics.stopContent(this._movieId?.toString());

        this._captureWatchtime();

        // this._saveProgress();
    }

    override _handleBack() {
        this._exitVideo();
    }

    override _handleEnter() {
        this.Controls.setPlayPauseState();

        this.$triggerVideoPlayPause();
    }

    override _handlePlayPause() {
        this.Controls.setPlayPauseState();

        this.$triggerVideoPlayPause();
    }

    override _handleRight() {
        this.Controls.setForwardState();

        this.$triggerVideoForward();
    }

    override _handleLeft() {
        this.Controls.setRewindState();

        this.$triggerVideoRewind();
    }

    override _handleForward() {
        this.Controls.setForwardState();

        this.$triggerVideoForward();
    }

    override _handleRewind() {
        this.Controls.setRewindState();

        this.$triggerVideoRewind();
    }

    override _handlePlay() {
        this.Controls.setPlayPauseState();

        this.$triggerVideoPlay();
    }

    override _handlePause() {
        this.Controls.setPlayPauseState();

        this.$triggerVideoPause();
    }

    override _handleStop() {
        this.Controls.setPlayPauseState();

        this.$triggerVideoPause();
    }

    override _captureKey() {
        this._setPlaybackStateTimeout();

        return false;
    }

    _exitVideo() {
        this.Loading.patch({
            smooth: {
                alpha: 0
            }
        });

        Registry.clearTimeouts();

        VideoPlayer.pause();

        if (!Router.getHistory().length) {
            // deeplink
            Router.navigate(getHomePage(), false);
        } else {
            const { currentEpisodeIndex, currentSeasonIndex } = this._getCurrentMedia();

            if (currentEpisodeIndex) {
                window.selectedIndex = currentEpisodeIndex;
                window.seasonSelectedIndex = currentSeasonIndex;
            }

            if (window.selectedMovie?.season?.series?.id) {
                Router.navigate(`series/${window.selectedMovie?.season?.series?.id}`, false);
            } else {
                Router.back();
            }
        }
    }

    _handlePlayerTopHovered() {
        this._setState("TopState");
    }

    _setPlaybackStateTimeout() {
        this._clearControlsTimeout();

        this._controlsTimeout = Registry.setTimeout(() => {
            this._controlsTimeout = undefined;

            if (!this._playing || this._loading) return;

            this._clearLoadingTimeout();
            this._setState("PlaybackState");

            this._controlsHideDelay = CONTROLS_HIDE_DELAY;
        }, this._controlsHideDelay);
    }

    _clearControlsTimeout() {
        if (this._controlsTimeout) {
            Registry.clearTimeout(this._controlsTimeout);
        }
    }

    _clearLoadingTimeout() {
        if (this._loadingTimeout) {
            Registry.clearTimeout(this._loadingTimeout);
            this._loadingTimeout = undefined;
        }
    }

    _destroyPlayer() {
        this._duration = 0;

        VideoPlayer.close();
        VideoPlayer.clear();
    }

    _initPlayer(data: PlaybackModel[]) {
        if (window.selectedMovie) {
            this._startTime = Date.now();
            this.SeriesName.patch({
                text: window.selectedMovie.series?.title || window.selectedMovie.season?.series?.title || ""
            });

            const { currentEpisode } = this._getCurrentMedia();

            this._movieTitle = currentEpisode?.title || window.selectedMovie.title || "";

            this.Name.patch({
                text: this._movieTitle
            });

            this.Description.patch({
                text: {
                    text: window.selectedMovie.description || ""
                }
            });
        }

        this._destroyPlayer();

        VideoPlayer.unloader(unloader);
        VideoPlayer.loader(loader);
        VideoPlayer.open(data[0]!.url, { startPosition: data[0]!.content?.progress?.current || 0 });

        this.application.emit("hideBackground");
    }

    _handleCurrentTimeChange(time: number, togglePlay = false, rewind = true) {
        if (rewind) {
            this._shifting = true;
            if (this._shiftTimeout) {
                Registry.clearTimeout(this._shiftTimeout);
                this._shiftTimeout = undefined;
            }
            this._shiftTimeout = Registry.setTimeout(() => {
                this._shiftTimeout = undefined;
                this._shifting = false;
                this._rewindSeconds = MIN_REWIND;
            }, 450);
        }

        // VideoPlayer.pause();
        this._currentTime = Math.min(this._duration, Math.max(0, time));

        this.Controls.patch({
            currentTime: this._currentTime
        });

        if (togglePlay) {
            if (this._playTimeout) clearTimeout(this._playTimeout);
            this._playTimeout = undefined;
            this._playTimeout = Registry.setTimeout(() => {
                this._playTimeout = undefined;

                VideoPlayer.play();
            }, 450);
        }

        if (!this._progressTimeout) {
            this._progressTimeout = Registry.setTimeout(async () => {
                await this._saveProgress();
            }, 5000);
        }
    }

    async _saveProgress() {
        if (this._movieId) {
            try {
                await updateProgress(this._movieId, Math.round(VideoPlayer.currentTime || 0));
            } catch (e) {
                console.log(e);
            }
        }

        this._progressTimeout = undefined;
    }

    _setLoadingState() {
        this._clearLoadingTimeout();

        this._loadingTimeout = Registry.setTimeout(() => {
            this._setState("LoadingState");

            this._loadingTimeoutDelay = LOADING_DELAY;
        }, this._loadingTimeoutDelay);
    }

    _setControlsState() {
        this._clearLoadingTimeout();
        this._setState("ControlsState");
    }

    _hideControls() {
        this.Loading.patch({
            smooth: {
                alpha: 0
            }
        });

        this.TopContainer.patch({
            smooth: {
                y: -400
            }
        });

        this.Overlay.patch({
            smooth: {
                alpha: 0
            }
        });

        this.Controls.patch({
            smooth: {
                alpha: 0
            }
        });

        this.BottomContainer.patch({
            smooth: {
                y: 1080
            }
        });
    }

    _resumePlaying() {
        if (!this._playing) return;

        VideoPlayer.play();
    }

    _handleVideoEnd() {
        if (
            window.selectedMovie?.__typename === "TvSeries" ||
            window.selectedMovie?.__typename === "Episode" ||
            window.selectedMovie?.__typename === "Collection"
        ) {
            const { currentEpisodeIndex, currentEpisodeList } = this._getCurrentMedia();

            const nextEp =
                typeof currentEpisodeIndex !== "undefined" ? currentEpisodeList[currentEpisodeIndex + 1] : undefined;

            if (nextEp) {
                Router.navigate(`playback/${nextEp.id}`, false);
            } else {
                this._exitVideo();
            }
        } else {
            this._exitVideo();
        }
    }

    _getCurrentMedia() {
        let currentEpisodeIndex =
            window.selectedMovie?.__typename === "Collection"
                ? window.selectedMovie.items?.findIndex((item) => item.id === this._movieId)
                : undefined;

        const currentSeasonIndex = (window.selectedMovie?.series?.seasons || window.selectedMovie?.seasons)?.findIndex(
            (season) => {
                const index = season.episodes!.findIndex((ep) => ep.id === this._movieId);

                if (index > -1) {
                    currentEpisodeIndex = index;
                    return true;
                } else {
                    return false;
                }
            }
        )!;

        const currentSeason = (window.selectedMovie?.series?.seasons || window.selectedMovie?.seasons || [])[
            currentSeasonIndex
        ];

        const currentEpisodeList =
            window.selectedMovie?.__typename === "Collection" && window.selectedMovie?.items
                ? window.selectedMovie?.items
                : currentSeason?.episodes || [];

        const currentEpisode =
            typeof currentEpisodeIndex === "undefined" ? undefined : currentEpisodeList[currentEpisodeIndex];

        return {
            currentEpisodeIndex,
            currentSeasonIndex,
            currentEpisode,
            currentEpisodeList
        };
    }

    $exitVideo() {
        this._exitVideo();
    }

    $triggerVideoPlayPause() {
        if (this._playing) {
            this.$triggerVideoPause();
        } else {
            this.$triggerVideoPlay();
        }
    }

    $triggerVideoPlay() {
        this._playing = true;

        VideoPlayer.play();

        this.Controls.triggeredPlay();
    }

    $triggerVideoPause() {
        this.Overlay.patch({
            smooth: {
                alpha: 1
            }
        });

        this._playing = false;

        VideoPlayer.pause();

        this.Controls.triggeredPause();
    }

    $triggerVideoRewind() {
        this._seeking = true;

        this._rewindSeconds = Math.min(this._rewindSeconds + 5, MAX_REWIND);

        VideoPlayer.pause();
        VideoPlayer.skip(this._rewindSeconds * -1);

        this._handleCurrentTimeChange(this._currentTime - this._rewindSeconds, this._playing, true);
    }

    $triggerVideoForward() {
        this._seeking = true;

        this._rewindSeconds = Math.min(this._rewindSeconds + 5, MAX_REWIND);

        VideoPlayer.pause();
        VideoPlayer.skip(this._rewindSeconds);

        this._handleCurrentTimeChange(this._currentTime + this._rewindSeconds, this._playing, true);
    }

    $videoPlayerTimeUpdate({ videoElement }: VideoPlayerEvent) {
        if (!this._shifting) {
            this._handleCurrentTimeChange(videoElement.currentTime);
        }
    }

    $videoPlayerDurationChange({ videoElement }: VideoPlayerEvent) {
        if (videoElement.duration && !this._duration) {
            this._duration = videoElement.duration;

            this.Controls.patch({
                currentTime: 0,
                duration: this._duration
            });
        }
    }

    $videoPlayerPause() {
        if (this._loading) return;

        this._setControlsState();

        if (this._seeking) return;

        this._playing = false;
        this.Controls.setPlayState(this._playing);

        Metrics.mediaPause(this._movieId!.toString());
    }

    $videoPlayerStop() {
        this._setControlsState();

        this._playing = false;

        this.Controls.setPlayState(this._playing);
    }

    $videoPlayerPlaying() {
        if (this._loading) return;

        this._setPlaybackStateTimeout();

        this._playing = true;

        this.Controls.setPlayState(this._playing);

        this.Overlay.patch({
            smooth: {
                alpha: 0
            }
        });

        Metrics.mediaPlay(this._movieId!.toString());
    }

    $videoPlayerCanPlay() {
        if (this._playing) {
            VideoPlayer.play();
        } else {
            VideoPlayer.pause();
        }

        this._setControlsState();

        this._loading = false;
    }

    $videPlayerLoading() {
        this._setLoadingState();

        // this._playing = false;
    }

    $videoPlayerWaiting() {
        this._loading = true;

        this._setLoadingState();
    }

    $videoPlayerError() {
        this._loading = true;

        this._setLoadingState();
    }

    $videoPlayerSeeking() {
        this._setLoadingState();
    }

    $videoPlayerSeeked() {
        if (this._playing) {
            VideoPlayer.play();
        }
    }

    $videoPlayerEnded() {
        this._handleVideoEnd();

        this.Overlay.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    _captureWatchtime() {
        const duration = (Date.now() - this._startTime) / 1000; // In seconds
        posthog.capture("Watchtime", {
            duration: duration,
            id: this._movieId,
            title: this._movieTitle
        });
    }

    static override _states() {
        return [
            class TopState extends this {
                override _getFocused() {
                    return this.Top;
                }

                override _handleDown() {
                    this._setControlsState();
                }

                override _handleBack() {
                    if (this._playing) {
                        this._controlsHideDelay = 0;
                        this._setPlaybackStateTimeout();
                    } else {
                        return false;
                    }
                }

                override _handleLeft() {
                    return true;
                }
            },
            class ControlsState extends this {
                override $enter() {
                    this._setPlaybackStateTimeout();
                }

                override _getFocused() {
                    return this.Controls;
                }

                override _handleUp() {
                    this._clearLoadingTimeout();
                    this._setState("TopState");
                }

                override _handleBack() {
                    if (this._playing) {
                        this._controlsHideDelay = 0;
                        this._setPlaybackStateTimeout();
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            class BottomState extends this {
                override _getFocused() {
                    return this.Controls;
                }

                override _handleUp() {
                    this._setControlsState();
                }
            },

            class LoadingState extends this {
                override _getFocused() {
                    return this.Controls;
                }

                override $enter() {
                    this.Loading.patch({
                        smooth: {
                            alpha: 1
                        }
                    });
                }

                override $exit() {
                    this._clearLoadingTimeout();

                    this.Loading.patch({
                        smooth: {
                            alpha: 0
                        }
                    });
                }
            },
            class PlaybackState extends this {
                override _captureKey() {
                    // Back button timeout not to trigger controls to exit
                    Registry.setTimeout(() => {
                        this._setControlsState();
                    }, 10);

                    return false;
                }

                _handleClick() {
                    this._setControlsState();
                }

                override _getFocused() {
                    return this.Controls;
                }

                override $enter() {
                    this._hideControls();
                }

                override $exit() {
                    this._clearLoadingTimeout();

                    this.TopContainer.patch({
                        smooth: {
                            y: 0
                        }
                    });

                    this.Controls.patch({
                        smooth: {
                            alpha: 1
                        }
                    });

                    this.BottomContainer.patch({
                        smooth: {
                            y: theme.layout.screenH - 200 * 2
                        }
                    });
                }
            }
        ];
    }

    set data(data: PlaybackModel[]) {
        this._initPlayer(data);
    }

    set redirect(redirectToMovie: boolean) {
        if (redirectToMovie) {
            if (Router.getHistory().length > 0) {
                Router.back();
            } else {
                Router.navigate(getHomePage(), false);
            }
        }
    }

    override set params(args: { movieId: string }) {
        this.application.emit("showBackground");

        if (this._movieId) {
            Metrics.stopContent(this._movieId?.toString());

            this._playing = true;
            this.Controls.setPlayState(this._playing);
        }

        Metrics.startContent(args.movieId?.toString());

        this._movieId = args.movieId;

        console.log("args", args);
    }
}
