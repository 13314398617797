import { Lightning, Colors } from "@lightningjs/sdk";
import { ChannelItemModel, ProgramModel } from "../../../lib/models";
import theme from "../../../lib/theme";
import { MediaPoster } from "../../media/MediaPoster/MediaPoster";

interface ChannelItemSpec extends Lightning.Component.TemplateSpec {
    Background: object;
    Content: {
        Logo: {
            Background: object;
            Logo: typeof MediaPoster;
        };
        Info: {
            Top: {
                ChannelName: object;
                ProgramName: object;
            };
            Bottom: {
                Time: object;
                ProgressBar: {
                    Progress: object;
                };
            };
        };
    };
}

export class ChannelItem extends Lightning.Component<ChannelItemSpec> {
    private _channel: ChannelItemModel | null = null;
    private _currentProgram: ProgramModel | null = null;
    private _updateInterval: number | null = null;

    static override _template(): Lightning.Component.Template<ChannelItemSpec> {
        return {
            w: theme.channelItem.w,
            h: theme.channelItem.h,
            Background: {
                w: (w) => w,
                h: (h) => h,
                rect: true,
                color: Colors(theme.color.backgroundNormal).get(),
                shader: { type: Lightning.shaders.RoundedRectangle, radius: theme.channelItem.borderRadius }
            },
            Content: {
                x: theme.channelItem.padding.x,
                y: theme.channelItem.padding.y,
                w: (w) => w - theme.channelItem.padding.x * 2,
                h: (h) => h - theme.channelItem.padding.y * 2,
                flex: { direction: "row", justifyContent: "space-between", alignContent: "space-between" },
                Logo: {
                    h: (h) => h,
                    w: theme.channelItem.bg.w,
                    Background: {
                        w: (w) => w,
                        h: (h) => h,
                        rect: true,
                        color: Colors(theme.color.backgroundLight).get(),
                        shader: {
                            type: Lightning.shaders.RoundedRectangle,
                            radius: theme.channelItem.bg.borderRadius
                        }
                    },
                    Logo: {
                        x: 16,
                        y: 16,
                        w: theme.channelItem.logo.w,
                        h: theme.channelItem.logo.h,
                        type: MediaPoster,
                        useBorder: false
                    }
                },
                Info: {
                    flexItem: { grow: 1, marginLeft: 30 },
                    flex: { direction: "column" },
                    Top: {
                        y: 4,
                        flex: { direction: "column" },
                        flexItem: { grow: 1 },
                        ChannelName: {
                            text: {
                                text: "",
                                fontSize: theme.channelItem.channelName.fontSize,
                                fontFace: "Bold",
                                textColor: Colors(theme.color.text).get(),
                                lineHeight: theme.channelItem.channelName.lineHeight,
                                wordWrap: true,
                                wordWrapWidth: theme.channelItem.w - theme.channelItem.logo.w - 80,
                                maxLines: 1,
                                textOverflow: "ellipsis"
                            }
                        },
                        ProgramName: {
                            y: -6,
                            text: {
                                text: "",
                                fontSize: theme.channelItem.programName.fontSize,
                                fontFace: "Regular",
                                textColor: Colors(theme.color.text).alpha(0.7).get(),
                                lineHeight: theme.channelItem.programName.lineHeight,
                                wordWrap: true,
                                wordWrapWidth: theme.channelItem.w - theme.channelItem.logo.w - 80,
                                maxLines: 1,
                                textOverflow: "ellipsis"
                            }
                        }
                    },
                    Bottom: {
                        y: -6,
                        flex: { direction: "column" },
                        Time: {
                            text: {
                                text: "",
                                fontSize: theme.channelItem.time.fontSize,
                                fontFace: "Regular",
                                textColor: Colors(theme.color.textTertiary).get(),
                                lineHeight: theme.channelItem.time.lineHeight
                            }
                        },
                        ProgressBar: {
                            w: theme.channelItem.progressBar.width,
                            h: theme.channelItem.progressBar.height,
                            rect: true,
                            color: Colors(theme.color.progressBackground).get(),
                            shader: {
                                type: Lightning.shaders.RoundedRectangle,
                                radius: 3
                            },
                            Progress: {
                                w: 0,
                                h: theme.channelItem.progressBar.height,
                                rect: true,
                                color: Colors(theme.color.progressForeground).get(),
                                shader: {
                                    type: Lightning.shaders.RoundedRectangle,
                                    radius: 3
                                }
                            }
                        }
                    }
                }
            }
        };
    }

    set item(channel: ChannelItemModel) {
        this._channel = channel;
        this._updateChannelInfo();
        this._startProgressUpdate();
    }

    private _updateChannelInfo() {
        if (!this._channel) return;

        const { name, logo } = this._channel;
        this._currentProgram = this._getCurrentProgram();
        const programTitle = this._currentProgram?.title ?? "";

        const programTime = this._currentProgram
            ? `${this.formatTime(this._currentProgram.start)} - ${this.formatTime(this._currentProgram.stop)}`
            : "";

        this.patch({
            Content: {
                Logo: {
                    Logo: {
                        data: this._channel
                    }
                },
                Info: {
                    Top: {
                        ChannelName: { text: { text: name } },
                        ProgramName: { text: { text: programTitle } }
                    },
                    Bottom: {
                        Time: { text: { text: programTime } }
                    }
                }
            }
        });

        this._updateProgress();
    }

    private _getCurrentProgram(): ProgramModel | null {
        if (!this._channel || !this._channel.programs) return null;

        const now = new Date();
        return (
            this._channel.programs.find((program) => {
                const start = new Date(program.start);
                const end = new Date(program.stop);
                return now >= start && now < end;
            }) || null
        );
    }

    private _updateProgress() {
        if (this._currentProgram) {
            const now = new Date();
            const start = new Date(this._currentProgram.start);
            const end = new Date(this._currentProgram.stop);

            if (now >= start && now < end) {
                const totalDuration = end.getTime() - start.getTime();
                const elapsed = now.getTime() - start.getTime();
                const progress = Math.min(1, Math.max(0, elapsed / totalDuration));

                this.patch({
                    Content: {
                        Info: {
                            Bottom: {
                                ProgressBar: {
                                    Progress: { w: theme.channelItem.progressBarW * progress }
                                }
                            }
                        }
                    }
                });
            } else {
                this._updateChannelInfo();
            }
        } else {
            // If there's no current program, reset the progress bar
            this.patch({
                Content: {
                    Info: {
                        Bottom: {
                            ProgressBar: {
                                Progress: { w: 0 }
                            }
                        }
                    }
                }
            });
        }
    }

    private _startProgressUpdate() {
        this._stopProgressUpdate();
        this._updateInterval = setInterval(() => {
            this._updateProgress();
        }, 30000) as unknown as number; // Update every 30 seconds
    }

    private _stopProgressUpdate() {
        if (this._updateInterval) {
            clearInterval(this._updateInterval);
            this._updateInterval = null;
        }
    }

    private formatTime(dateString: string): string {
        return new Date(dateString).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    }

    override _focus() {
        this.patch({
            Background: {
                smooth: { color: Colors(theme.color.backgroundFocused).get() }
            },
            Content: {
                Logo: {
                    Background: {
                        smooth: { color: Colors(theme.color.backgroundDark).get() }
                    }
                }
            }
        });
        this._channel && this.fireAncestors("$onChannelFocus", this._channel);
    }

    override _unfocus() {
        this.patch({
            Background: {
                smooth: { color: Colors(theme.color.backgroundNormal).get() }
            },
            Content: {
                Logo: {
                    Background: {
                        smooth: { color: Colors(theme.color.backgroundLight).get() }
                    }
                }
            }
        });
    }

    static get width() {
        return theme.channelItem.w;
    }

    static get height() {
        return theme.channelItem.h;
    }

    override _handleEnter() {
        this._channel && this.fireAncestors("$liveChannelSelected", this._channel);
    }

    override _disable() {
        this._stopProgressUpdate();
    }
}
