import { Colors, Lightning } from "@lightningjs/sdk";
import { ProgressBar } from "@lightningjs/ui";
import theme from "../../../lib/theme";
import { formatDuration, formatTime } from "../../../lib/utils";

interface MediaDurationWithProgressTemplateSpec extends Lightning.Component.TemplateSpec {
    progress: number;
    duration: number;
    fontSize: number;

    Label: object;
    Progress: typeof ProgressBar;
}
export class MediaDurationWithProgress
    extends Lightning.Component<MediaDurationWithProgressTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MediaDurationWithProgressTemplateSpec>
{
    _progress: number | undefined;
    _duration: number | undefined;

    static override _template(): Lightning.Component.Template<MediaDurationWithProgressTemplateSpec> {
        return {
            // h: 40,
            Label: {
                text: {
                    text: "",
                    fontSize: 30,
                    wordWrap: true,
                    lineHeight: 30
                }
            },
            Progress: {
                alpha: 0,
                type: ProgressBar,
                y: 30,
                h: 6,
                w: 186,
                value: 0,
                progressColor: Colors(theme.color.contrast).get(),
                backgroundColor: Colors(theme.color.contrast).alpha(0.4).get()
            }
        };
    }
    readonly Label = this.getByRef("Label")!;
    readonly Progress = this.getByRef("Progress")!;

    _update() {
        if (this._progress || this._duration) {
            this.patch({
                h: 40
            });
        }
        if (this._progress && this._duration) {
            this.Label.patch({
                y: -10,
                text: {
                    text: `${formatDuration(this._progress, this._duration, true)}`
                }
            });

            this.Progress.patch({
                alpha: 1
            });
            this.Progress.progress(this._progress / this._duration);
        } else {
            this.Label.patch({
                y: 0,
                text: {
                    text: `${formatTime(this._duration)}`
                }
            });

            this.Progress.patch({
                alpha: 0
            });
        }
    }

    set progress(value: number) {
        this._progress = value;

        this._update();
    }

    set duration(value: number) {
        this._duration = value;

        this._update();
    }

    set fontSize(value: number) {
        this.Label.patch({
            text: {
                fontSize: value
            }
        });

        this.Progress.patch({
            y: 20
        });
    }
}
