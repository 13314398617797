import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import { TranslatableObject, TranslatableText } from "../../TranslatableText/TranslatableText";
import theme from "../../../../lib/theme";

interface SettingsItemTextTemplateSpec extends Lightning.Component.TemplateSpec {
    title: string | TranslatableObject;
    info: string;
    content: any;

    Content: {
        // Label: typeof TranslatableText;
        Row: {
            Label: typeof TranslatableText;
            Icon: object;
        };
    };
    ListContainer: {
        Border: object;
        Text: typeof TranslatableText;
        Content: object;
    };
}

export class SettingsItemText
    extends Lightning.Component<SettingsItemTextTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsItemTextTemplateSpec>
{
    _labelKey = "label";
    _valueKey = "value";
    _options: any[] = [];
    _selectedOption: string | undefined;
    _focusList = false;

    readonly Content = this.getByRef("Content")!;
    // readonly Label = this.Content.getByRef("Label")!;
    readonly Row = this.Content.getByRef("Row")!;
    readonly Icon = this.Row.getByRef("Icon")!;

    readonly Label = this.Row.getByRef("Label")!;
    readonly ListContainer = this.getByRef("ListContainer")!;
    readonly Text = this.ListContainer.getByRef("Text")!;
    readonly CustomContent = this.ListContainer.getByRef("Content")!;

    static override _template(): Lightning.Component.Template<SettingsItemTextTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            h: theme.settings.itemH,
            w: 800,
            Content: {
                mountY: 0.5,
                y: (h) => h / 2,
                collision: true,
                zIndex: 10,
                flex: { direction: "column", alignItems: "flex-start", justifyContent: "flex-start" },
                Row: {
                    w: theme.settings.w - 46 * 2,
                    x: 46,
                    flex: { justifyContent: "space-between", alignItems: "center" },
                    Label: {
                        type: TranslatableText,
                        text: {
                            fontSize: 28,
                            textColor: Colors(theme.color.contrast).get(),
                            fontFace: "Secondary-Regular"
                        }
                    },
                    Icon: {
                        alpha: 1,
                        w: 15,
                        h: 27,
                        texture: Lightning.Tools.getSvgTexture(Utils.asset("icons/chevron-right.svg"), 15, 27)
                    }
                }
            },
            ListContainer: {
                zIndex: 10,
                flexItem: { marginTop: 37 },
                y: 0,
                w: 900,
                alpha: 0,
                x: theme.settings.w + 52,
                Border: {
                    w: 2,
                    h: theme.layout.screenH - 100,
                    x: 0,
                    rect: true,
                    color: Colors(theme.color.textDark).alpha(0.5).get()
                },
                Text: {
                    w: (w) => w,
                    x: 100,
                    type: TranslatableText,
                    text: {
                        textColor: Colors(theme.color.textDark).get(),
                        fontSize: 28,
                        wrap: true,
                        wrapWidth: 800
                    }
                },
                Content: {
                    x: 100
                }
            }
        };
    }

    set content(value: any) {
        this.CustomContent.children = [
            {
                type: value
            }
        ];
    }

    set title(value: string | TranslatableObject) {
        if (typeof value === "string") {
            this.Label.patch({
                key: value
            });
        } else {
            this.Label.patch({
                translate: value
            });
        }
    }

    set info(value: any) {
        this.Text.patch({
            key: value,
            text: {
                textColor: Colors(theme.color.textDark).get(),
                fontSize: 28,
                wrap: true,
                wrapWidth: 800
            }
        });
    }

    override _init() {
        this.ListContainer.patch({
            // @ts-ignore
            y: -1 * this.parent?.parent?.parent?.assignedY || 0
        });
    }

    override _focus() {
        this.Label.patch({
            text: {
                textColor: Colors(theme.color.textHighlight).get()
            }
        });

        this.ListContainer.patch({
            smooth: { alpha: 1 }
        });
    }

    override _unfocus() {
        this.Label.patch({
            text: {
                textColor: Colors(theme.color.contrast).get()
            }
        });

        this.ListContainer.patch({
            smooth: {
                alpha: 0
            }
        });
    }

    override _handleLeft() {
        if (!this._focusList) return false;

        this._focusList = false;
    }

    override _handleRight() {
        if (!this._options?.length) return false;

        this._focusList = true;
    }

    focusList() {
        this._handleRight();
    }

    override _getFocused() {
        return undefined;
    }
}
