import { Colors, Lightning, Storage } from "@lightningjs/sdk";
import QRCode from "qrcode";
import { TranslatableText } from "../../../components/data-display/TranslatableText/TranslatableText";
import theme from "../../../lib/theme";
import { STORAGE_KEYS } from "../../../lib/utils";

interface IAPQrTemplateSpec extends Lightning.Component.TemplateSpec {
    url: string;
    Label: typeof TranslatableText;
    QrCode: object;
    Link: object;
}

export class IAPQr
    extends Lightning.Component<IAPQrTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<IAPQrTemplateSpec>
{
    _url = "";

    readonly Label = this.getByRef("Label")!;
    readonly QrCode = this.getByRef("QrCode")!;
    readonly Link = this.getByRef("Link")!;

    static override _template(): Lightning.Component.Template<IAPQrTemplateSpec> {
        return {
            w: 360,
            h: 600,
            flex: { direction: "column", justifyContent: "center", alignItems: "center" },
            Label: {
                flexItem: { marginBottom: 24 },
                type: TranslatableText,
                translate: {
                    key: "auth.login",
                    value: ""
                },
                text: {
                    textAlign: "center",
                    fontFace: "SemiBold",
                    fontSize: 28,
                    wordWrap: true,
                    wordWrapWidth: 680,
                    textColor: Colors(theme.color.contrast).alpha(0.8).get()
                }
            },
            QrCode: { w: 260, h: 260 },
            Link: {
                flexItem: { marginTop: 16 },
                h: 40,
                text: {
                    text: "",
                    textAlign: "center",
                    fontFace: "SemiBold",
                    fontSize: 28,
                    textColor: Colors(theme.color.contrast).alpha(0.8).get()
                }
            }
        };
    }

    override _getFocused() {
        return this;
    }

    set url(value: "login" | "signup") {
        this._url = value;
        this._active();
    }

    override async _active() {
        if (this._url) {
            const user = Storage.get(STORAGE_KEYS.user);
            const qrcodeLogin = await QRCode.toDataURL(this._url, {
                errorCorrectionLevel: "H",
                type: "image/jpeg"
            });
            this.QrCode.patch({
                src: qrcodeLogin
            });
            this.Link.patch({
                text: {
                    text: this._url
                }
            });
        } else {
            this.QrCode.patch({
                src: undefined
            });
            this.Link.patch({
                text: {
                    text: ""
                }
            });
        }
    }

    override async _inactive() {}
}
